.column-text-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.main-text-block {
    p {
        font-family: $notesEsa;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        font-size: 0.78rem;
        line-height: 1.3;
        font-weight: normal;
        margin-bottom: 1.1rem;
        strong,
        b {
            font-weight: bold;
        }
    }
}
.button-with-label {
    display: flex;
    align-items: center;
    p {
        margin: 0 1.22rem 0 0;
    }
}
.people-list-block {
    h2 {
        margin-bottom: 3.33rem;
    }
    .people-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        li {
            flex: 1 0 calc(20% - 3.33rem);
            max-width: calc(20% - 3.33rem);
            margin-right: 3.33rem;
            line-height: 1;
            &:last-child {
                margin-right: 0;
            }
            .text-h4-alt {
                letter-spacing: 1.5px;
                margin-bottom: 4px;
            }
            .underline {
                font-size: 0.78rem;
                line-height: 1;
            }
        }
    }
}
