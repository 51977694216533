@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1.2, 1.2);
    }
    100% {
        transform: scale(1, 1);
    }
}
.lisa-slider {
    padding: 4rem 0;

    .container {
        max-width: 100%;
        overflow: hidden;
        padding-left: calc((100vw - 1216px) / 2);
        display: flex;
        flex-direction: column;
        position: relative;

        .black-bg {
            display: none;
        }
    }

    .bottom-link {
        margin-right: calc((100vw - 1216px) / 2);
        padding-top: 1.5rem;
        display: flex;
        justify-content: flex-end;
    }

    &.has-black-bg {
        padding-bottom: 0;

        .container {
            padding-bottom: 5rem;
            display: flex;
            flex-direction: column;
            position: relative;

            .black-bg {
                display: flex;
                height: 50%;
                background-color: #000;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        .bottom-link {
            z-index: 5;

            a {
                color: white;

                .arrow-right {
                    background-image: url(../img/icons/arrow-right-white.svg);
                }
            }
        }
    }
    .slider-top-block {
        display:flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;
    }
    .slider-title {
        margin-bottom: 0;
        @include bg-yellow;
        display: inline-block;
    }

    .slider-arrows {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: calc((100vw - 1216px) / 2);

        a {
            background: url('../img/icons/arrow-right.svg') no-repeat 0 0;
            width: 25px;
            height: 14px;
            background-size: contain;
            text-indent: -9999px;
            display: block;
            margin: 0 1.83rem;
            transition: all 0.3s linear;

            &:first-child {
                transform: rotate(-180deg);
            }

            &:hover,
            &:focus {
                background: url('../img/icons/arrow-right-yellow.svg') no-repeat 0 0 !important;
            }
        }
    }

    .slick-track {
        display: flex !important;
    }

    .slide {
        &__inside {
            padding:0;
            min-height: 13rem;
            margin: 0 1.11rem 0 0;
            display:flex;
            &__left {
                max-width: 52%;
                flex:1 0 52%;
            }
            &__right {
                max-width: 48%;
                flex:1 0 48%;
                height:13rem;
                overflow: hidden;
                img {
                    height: 100%;
                    max-width: none;
                    -o-object-fit: cover;
                    object-fit: cover;
                    -o-object-position: 50% 50%;
                    object-position: 50% 50%;
                    position: relative;
                    width: 100%;
                }
            }
        }

        &__content {
            background-color: $black;
            padding: 0.89rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 13rem;

            &__top {
                .text-date {
                    @include text-h4;
                    color: $white;
                    font-family: $notesEsa;
                    margin-bottom: 10px;

                    p {
                        @include text-h4;
                        color: $white;
                        font-family: $notesEsa;
                        margin-bottom: 0;
                    }
                }

                h3 {
                    color: $white;
                    margin-bottom: 10px;

                    p {
                        color: $white;
                        margin-bottom: 0;
                    }
                }

                .text-subtitle {
                    font-size: 0.89rem;
                    color: $white;
                    margin: 0;

                    p {
                        font-size: 0.89rem;
                        color: $white;
                        margin: 0;
                    }
                }
            }

            &__bottom {
                .text-tags {
                    color: $yellow;
                    text-transform: uppercase;
                    font-size: 11px;
                    line-height: 1;
                    letter-spacing: 1px;
                    text-align: right;
                }

                .link-block {

                    a {
                        color: $white;

                        .esa-icon {
                            &.arrow-right {
                                background: url('../img/icons/arrow-right-white.svg') no-repeat 0 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1600px) {
        .slide{
            &__content{
                &__top {
                    h3 {
                        font-size:1.1rem;
                    }
                }
            }
        }
    }

    @media (max-width: 1100px) {
        padding: 2rem 0;
        .container {
            padding-left: 1rem;
            padding-right: 0;
        }

        .slider-arrows {
            margin-right: 0;
        }
    }

}
.osip-campaigns-block {
    .slider-top-block {
        padding: 0 0 0 1.35rem;
    }
    .lisa-slider {
        .slide{
            &__inside {
                &__left {
                    max-width: 100%;
                    flex:1 0 100%;
                }
            }
            &__content {
                padding: 1.78rem;
                &__top {
                    display: flex;
                    justify-content: space-between;
                    flex-flow: row wrap;

                    .text-date {
                        margin-bottom: 1.33rem;
                        padding: 4px 12px;
                        &:first-child {
                            color: $yellow;
                            letter-spacing: 1.5px;
                            transition: all 0.3s linear;
                        }
                        &:nth-child(2) {
                            text-transform: none;
                        }
                    }
                    .slide-title {
                        position: relative;
                        flex:1 0 100%;
                        max-width: 100%;
                        h3 {
                            transition: all 0.2s linear;
                            position: absolute;
                            left: 0;
                            width: 100%;
                            &:last-child {
                                text-transform:none;
                                font-size:0.89rem;
                                font-family: Arial;
                                line-height: 1.4;
                                opacity: 0;
                            }
                        }
                    }
                }
                &:hover {
                    .slide__content__top {
                        .text-date {
                            &:first-child {
                                background-color: $yellow;
                                color:$black;
                            }
                        }
                        .slide-title {
                            h3 {
                                &:first-child {
                                    transform: scale(0.7);
                                    opacity:0;
                                }
                                &:last-child {
                                    opacity: 1;
                                    animation: zoom-in-zoom-out 0.2s linear;
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}
