@import '../vendor/font-awesome';
@import '../vendor/bootstrap/bootstrap';
@import 'node_modules/slick-carousel/slick/slick.scss';
@import 'node_modules/slick-carousel/slick/slick-theme.scss';
@import '../../src/lyra/styles/modal';
@import '../../src/lyra/styles/choose-file';
@import '../../src/lyra/styles/password-input';
@import 'abstracts/abstracts';
@import 'base/base';
@import 'components/content-modules/content-modules';
@import 'components/main-components/main-components';
@import 'components/newsletter';
@import 'components/tec-news-block';
@import 'general/general';
@import 'pages/pages';

.slick-slider.allow-user-select {
    user-select: auto;
    -webkit-user-select: auto;
    -khtml-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
}

.invalid-feedback {
    display: block;
}

.container {
    &.topics {
        .pagination {
            margin-top: 3rem;

            li {
                margin-bottom: 0;
                padding-left: .2rem;

                &:before {
                    content: none;
                }
            }
        }
    }
}
