.three-columns-text {
    padding-bottom: 8.9rem;
    h1 {
        margin-bottom: 2.22rem;
    }
    .text-columns {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        .one-column {
            border-left: 1px solid $yellow;
            margin-right: 1.11rem;
            padding: 1.11rem 12px 0 12px;
            flex: 1 0 calc(33.33% - 24px);
            max-width: calc(33.33% - 24px);
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
