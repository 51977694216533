.tec-news-block {
    .items-wrap {
        display: flex;

        .items-left {
            width: 50%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .item-wrap {
                width: 50%;
                padding: 10px;

                .item-inner {
                    background-color: #cfcfcf;
                }
            }
        }

        .item-right {
            width: 50%;

            .item-wrap {
                padding: 10px;

                .item-inner {
                    background-color: #a9cfb9;
                }
            }
        }
    }
}
