.bart-list {
    font-family: $notesEsa;

    .cell {
        flex: 2;
        @include text-h4;

        &.text-bold {
            font-weight: bold;
        }

        &.text-size {
            &-sm {
                @extend .text-h4-alt;
            }

            &-md {
            }

            &-lg {
                @include text-h3;
            }
        }
    }

    .cell-toggle-item {
        flex: 1 0 1.3rem;
        max-width: 1.3rem;
    }

    .bart-list-header {
        display: flex;
        align-items: center;
        padding: 2rem 1.72rem 2rem 0;
        border-bottom: 1px solid $gray02;
    }

    .bart-list-item {
        border-bottom: 1px solid $gray02;

        .main-row {
            padding: 1.11rem 1.72rem 1.11rem 0;
            display: flex;
            align-items: center;

            .cell-toggle-item {
                width: 1.3rem;
                font-size: 1.3rem;
                text-align: center;
                cursor: pointer;

                &:before {
                    content: '+';
                }
            }
        }

        .extra-row {
            display: none;
            justify-content: space-between;
            transition: height 0.3s linear;
            padding-top: 1.11rem;
            padding-bottom: 1.11rem;

            .image {
                flex: 1 0 35%;
                max-width: 35%;
                margin-right: 10%;
            }

            .description {
                flex: 1 0 30%;
                max-width: 30%;
                margin-right: 6%;
                padding: 3.33rem 0 1.11rem 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .info {
                flex: 2;
                padding-top: 3.33rem;
                display: flex;

                .item-location {
                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;
                }
            }

            .info-2 {
                flex: 2;
                display: flex;
                flex-direction: column;
                padding-top: 3.33rem;

                .item-info-row {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    margin-bottom: 0.5rem;

                    .item-info-left {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        width: 45%;
                        padding-right: 0.3rem;
                    }

                    .item-info-right {
                        display: flex;
                        align-items: center;
                        width: 55%;
                    }
                }
            }
        }

        &.opened {
            .main-row {
                .cell-toggle-item {
                    &:before {
                        content: '-';
                    }
                }
            }

            .extra-row {
                display: flex;
            }
        }
    }
}
