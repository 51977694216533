.content-module-type-SimpleList {
    font-size: 93%;

    .title {
        font-family: "NotesEsa";
        text-transform: uppercase;
        color: #ccc;
    }

    .items-wrap {
        display: flex;
        flex-direction: row;
        padding-top: .5rem;

        .item {
            margin-right: 1.5rem;

            a, span {
                color: #000;
                text-transform: uppercase;
                border-bottom: 1px solid #000;
                padding-bottom: .2rem;
            }

            //span {
            //    border-bottom-width: 0;
            //}
        }
    }
}
