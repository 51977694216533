.contact-member-block {
    margin-top: 1.33rem;
    display: flex;
    align-items: center;
    .contact-member-avatar {
        flex: 1 0 3.33rem;
        max-width: 3.33rem;
        margin-right: 1.11rem;
    }
    .text-block {
        h4 {
            font-family: $notesEsa;
            font-weight: bold;
        }
        .small-text {
            font-size: 0.89rem;
            line-height: 1;
            margin: 4px 0;
            letter-spacing: 1px;
            display: block;
        }
        .team-contact {
            display: flex;
            align-items: center;
            line-height: 1;
            a {
                font-size: 14px;
                line-height: 1;
                color: $black;
                margin-right: 10px;
            }
        }
    }
}
