.image-block {
    &__inside {
        position: relative;
        padding: 3.92rem 0;
        text-align: center;
    }
    &__image {
        height: 100%;
        img {
            line-height: 0;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    &__text {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        z-index: 2;
        flex-direction: column;
        h1 {
            margin-bottom: 2.22rem;
        }
    }
    &__caption {
        text-align: left;
        margin-top: 1.11rem;
        color: $gray04;
        font-size: 14px;
        line-height: 1;
        font-weight: normal;
    }
    &.direction-columns {
        .image-block__inside {
            padding: 0;
            min-height: 10rem;
            .image-block__image {
                height: 100%;
                img {
                    max-width: initial;
                }
            }
            .image-block__text {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0 3.78rem 0 3.33rem;
                h1 {
                    margin-bottom: 0;
                    font-size: 2rem;
                    font-weight: 700;
                    text-align: left;
                    flex: 1 0 auto;
                    max-width: 30%;
                }
                h2 {
                    flex-grow: 2;
                    justify-content: flex-start;
                    text-align: left;
                    margin: 0 2.22rem;
                    font-size: 1.33rem;
                }
                .link-block {
                    flex: 1 0 auto;
                }
            }
        }
    }
}
@media (max-width: 1000px) {
    .image-block {
        &__inside {
            padding: 2.22rem 0;
        }
    }
}
@media (max-width: 1000px) {
    .image-block {
        &__text {
            h1 {
                margin-bottom: 1rem;
            }
        }
    }
}
@media (max-width: 760px) {
    .image-block {
        &__inside {
            padding: 0;
        }
        &__image {
            img {
                min-height: 15rem;
            }
        }
        &.direction-columns {
            .image-block {
                &__inside {
                    .image-block__text {
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding: 2rem 1rem;
                        h1 {
                            flex: auto;
                            max-width: 100%;
                        }
                        h2 {
                            flex: auto;
                            justify-content: center;
                            margin: 0;
                            padding: 1rem 0;
                        }
                        .link-block {
                            flex: auto;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 590px) {
    .image-block {
        &.direction-columns {
            .image-block {
                &__inside {
                    .image-block__text {
                        align-items: flex-start;
                        h1 {
                            font-size: 1.5rem;
                        }
                        h2 {
                            padding: 1rem 0;
                            margin: 0;
                            font-size: 1.1rem;
                        }
                        .link-block {
                            flex: auto;
                        }
                    }
                }
            }
        }
    }
}
