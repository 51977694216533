.home-search-block {
    h4 {
        margin-bottom:2.22rem;
    }
}
.search-input {
    position: relative;
    max-width: 22rem;
    input {
        width:100%;
        padding: 16px 20px;
        border:none;
        background-color: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 0;
        font-size: 14px;
        letter-spacing: 1px;
        &:focus,
        &:hover {
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
            outline: none;
            border:none;
        }
    }
    .icon-search,
    .arrow-left {
        position: absolute;
        right: 16px;
        top: 18px;
    }
}
.suggested-search {
    margin-top:2.22rem;
    h4 {
        margin-bottom: 1.11rem;
    }
    .suggested-search-list {
        display: flex;
        align-items: center;
        font-size:14px;
        letter-spacing: 1px;
        span {
            cursor: pointer;
        }
        .divider {
            margin:0 16px;
            cursor: none;
        }
    }
}
.search-block-content {
    position: relative;
    .suggested-search-dropdown {
        position: absolute;
        left: 0;
        top:3.35rem;
        background: $white;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
        width: 100%;
        padding: 1.22rem 1.22rem 0 1.22rem;
        max-width: 22rem;
        .one-item {
            color:#777;
            font-size: 14px;
            letter-spacing: 1px;
            margin-bottom: 1.33rem;
            cursor: pointer;
            &:hover {
                color:$black;
            }
        }
    }
}
.search-container {
    padding: 9.56rem 0 6rem 0;
    .search-block-content {
        padding-left: 7.5rem;
    }
}
.search-results-top {
    margin-bottom: 2.22rem;
    padding-left: 7.5rem;
}
.search-category-filter {
    margin-top:2.22rem;
    padding-left: 7.5rem;
    .gray-text {
        @include text-h4;
        color: rgba($black, 0.5);
        margin-top: 20px;
        font-family: $notesEsa;
    }
    .search-category-filter-list {
        display: flex;
        flex-flow: row wrap;
        max-width: 55%;
        .search-one-filter {
            background-color: $black;
            color: $white;
            padding: 4px 12px;
            letter-spacing: 1.5px;
            font-size: 0.86rem;
            line-height: 1.3;
            font-family: $notesEsa;
            font-weight: normal;
            text-transform: uppercase;
            margin:20px 20px 0 0;
            cursor:pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            .close-icon {
                margin-left:10px;
            }
            &:hover,
            &:focus,
            &.active {
                background-color: rgba($black, 0.7);
            }
        }
    }
}
.search-results-list-top {
    margin:4rem 0 2.22rem 0;
    padding-bottom: 1.5rem;
    padding-left: 7.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $yellow;
    .pagination {
        margin:0;
    }
    &.bottom {
        border-top: 1px solid $yellow;
        padding-top: 1.5rem;
        border-bottom: none;
        padding-bottom: 0;
    }
}
.search-results-list {
    padding-left: 7.5rem;
    .search-one-result {
        display: flex;
        align-items: stretch;
        margin-bottom: 2.22rem;
        .one-result-left {
            flex:1 0 10.4rem;
            max-width: 10.4rem;
            margin-right: 6.78rem;
            h4 {
                color:#777;
                margin-bottom: 1.67rem;
            }
        }
        .one-result-right {
            flex:2;
            h3 {
                font-size:1rem;
                text-transform: uppercase;
                font-family: Arial;
                margin-bottom: 16px;
            }
            .result-excerpt {
                margin-bottom: 16px;
                font-size: 1rem;
            }
            h4 {
                color:#777;
                margin-bottom: 16px;
            }
            a {
                color:#777;
                font-size:14px;
                letter-spacing: 1px;
                text-decoration: underline;
            }
        }
    }
}
@media (max-width: 1023px) {
    .search-container {
        .search-block-content {
            padding-left: 2rem;
        }
    }
    .search-results-top {
        padding-left: 2rem;
    }
    .search-results-list-top {
        padding-left: 2rem;
    }
    .search-category-filter {
        padding-left: 2rem;
    }
    .search-results-list {
        padding-left: 2rem;
    }
}
@media (max-width: 767px) {
    .search-container {
        .search-block-content {
            padding-left: 1rem;
        }
    }
    .search-results-top {
        padding-left: 1rem;
    }
    .search-category-filter {
        padding-left: 1rem;
        .search-category-filter-list {
            max-width: 100%;
        }
    }
    .search-results-list-top {
        padding-left: 1rem;
    }
    .search-results-list {
        padding-left: 1rem;
        .search-one-result {
            flex-flow: row wrap;
            .one-result-left {
                flex:1 0 100%;
                max-width: 100%;
                margin:0 0 2rem 0;
            }
        }
    }
}
