.items-list-title {
    margin-bottom: 5rem;
}

.items-list {
    //margin: 0 19.5%;

    .items-list-heading {
        display: flex;
        align-items: center;
        padding: 2rem 1.72rem 2rem 0;

        span {
            @include text-h4;
            font-family: $notesEsa;
            font-weight: bold;

            &:first-child {
                flex: 1 0 12%;
                max-width: 12%;
                margin-right: 7%;
            }

            &:nth-child(2) {
                flex: 1 0 8%;
                max-width: 8%;
                margin-right: 5%;
            }

            &:nth-child(3) {
                flex: 1 0 9%;
                max-width: 9%;
                margin-right: 6%;
            }

            &:nth-child(4) {
                flex: 1 0 9%;
                max-width: 9%;
                margin-right: 4%;
            }

            &:last-child {
                flex: 1 0 33%;
                max-width: 33%;
            }
        }
    }

    .items-list-single {
        padding: 1.11rem 0;
        border-top: 1px solid $gray02;
        transition: all 0.3s linear;

        &:hover,
        &:focus {
            background-color: $gray03;
        }

        .items-list-single-main {
            display: flex;
            justify-content: space-between;

            h3 {
                line-height: 1.87rem;
            }

            .toggle-item {
                width: 1.3rem;
                font-size: 1.3rem;
                font-family: $notesEsa;
                text-align: center;
                margin-left: 2.78rem;
                cursor: pointer;

                &:before {
                    content: '+';
                }
            }
        }

        .items-list-single-extra {
            display: none;
            justify-content: space-between;
            transition: height 0.3s linear;
            height: 0;
            opacity: 0;
            visibility: hidden;

            &__left {
                margin-right: 2.22rem;
                flex: 1 0 60%;
                max-width: 60%;

                .text {
                    &.headline {
                        font-weight: bold;
                        font-family: "NotesEsa";
                        text-transform: uppercase;
                        padding-bottom: 1rem;
                        font-size: 90%;
                    }

                    &.excerpt {
                    }

                    &.description {
                    }
                }

                .button-block {
                    margin-top: 3.78rem;
                }
            }
        }

        &:last-child {
            border-bottom: 1px solid $gray02;
        }

        &.opened {
            background-color: transparent;

            .items-list-single-main {
                .toggle-lab {
                    &:before {
                        content: '-';
                    }
                }
            }

            .items-list-single-extra {
                opacity: 1;
                visibility: visible;
                display: flex;
                height: auto;
                padding-top: 1.11rem;
            }
        }

        &.event-item-single {
            .items-list-single-main {
                padding: 1.11rem 1.72rem 1.11rem 0;
                display: flex;
                align-items: center;

                span {
                    &.toggle-event {
                        width: 1.3rem;
                        font-size: 1.3rem;
                        font-family: $notesEsa;
                        text-align: center;
                        cursor: pointer;

                        &:before {
                            content: '+';
                        }
                    }

                    &:first-child {
                        flex: 1 0 12%;
                        max-width: 12%;
                        margin-right: 7%;
                    }

                    &:nth-child(2) {
                        flex: 1 0 8%;
                        max-width: 8%;
                        margin-right: 5%;
                    }

                    &:nth-child(3) {
                        flex: 1 0 9%;
                        max-width: 9%;
                        margin-right: 6%;
                    }

                    &:nth-child(4) {
                        flex: 1 0 9%;
                        max-width: 9%;
                        margin-right: 4%;
                    }

                    &:nth-child(5) {
                        flex: 2;
                        margin-right: 1rem;
                    }

                    &:last-child {
                        flex: 1 0 1.3rem;
                        max-width: 1.3rem;
                    }

                    .text-mobile-visible {
                        display: none;
                    }
                }
            }

            .items-list-single-extra {
                justify-content: space-between;
                padding: 0 1.72rem 0 0;

                .item-image {
                    flex: 1 0 35%;
                    max-width: 35%;
                    margin-right: 10%;
                }

                .item-description {
                    flex: 1 0 30%;
                    max-width: 30%;
                    margin-right: 6%;
                    padding: 3.33rem 0 1.11rem 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .button-block {
                        margin-top: 1.5rem;
                    }
                }

                .item-info {
                    flex: 2;
                    padding-top: 3.33rem;
                    display: flex;

                    .item-location {
                        display: flex;
                        flex-direction: column;
                        margin-left: 10px;
                    }
                }

                .item-info-2 {
                    flex: 2;
                    display: flex;
                    flex-direction: column;
                    padding-top: 3.33rem;

                    .item-info-row {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        margin-bottom: 0.5rem;

                        .item-info-left {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            width: 45%;
                            padding-right: 0.3rem;
                        }

                        .item-info-right {
                            display: flex;
                            align-items: center;
                            width: 55%;
                        }
                    }
                }
            }
        }
    }

    &.events-list {
        margin: 0 1.72rem;
    }
}

@media (max-width: 1100px) {
    .items-list {
        &.events-list {
            margin: 0;

            .items-list-heading {
                padding: 2rem 0.5rem 2rem 0;

                span {
                    &:first-child {
                        margin-right: 1rem;
                    }

                    &:nth-child(2) {
                        flex: 1 0 10%;
                        max-width: 10%;
                        margin-right: 1rem;
                    }

                    &:nth-child(3) {
                        margin-right: 1rem;
                    }

                    &:nth-child(4) {
                        flex: 1 0 15%;
                        max-width: 15%;
                        margin-right: 1rem;
                    }
                }
            }
        }

        .items-list-single {
            &.event-item-single {
                .items-list-single-main {
                    padding-right: 0.5rem;

                    span {
                        &:first-child {
                            margin-right: 1rem;
                        }

                        &:nth-child(2) {
                            flex: 1 0 10%;
                            max-width: 10%;
                            margin-right: 1rem;
                        }

                        &:nth-child(3) {
                            margin-right: 1rem;
                        }

                        &:nth-child(4) {
                            flex: 1 0 15%;
                            max-width: 15%;
                            margin-right: 1rem;
                        }

                        &:nth-child(5) {
                            margin-right: 1rem;
                        }
                    }

                    .text-h3 {
                        font-size: 1rem;
                    }
                }

                .items-list-single-extra {
                    padding-right: 0.5rem;

                    .item-image {
                        margin-right: 1.5rem;
                    }

                    .item-description {
                        margin-right: 1.5rem;
                        flex: 1 0 40%;
                        max-width: 40%;
                    }

                    .item-info-2 {
                        .item-info-row {
                            flex-flow: row wrap;
                            margin-bottom: 1rem;

                            .item-info-left {
                                width: 100%;
                                padding: 0 0 0.5rem 0;
                                font-size: 1rem;
                            }

                            .item-info-right {
                                width: 100%;
                                justify-content: flex-end;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 750px) {
    .items-list {
        &.events-list {
            margin: 0;

            .items-list-heading {
                padding: 1rem 0.5rem 1rem 0;

                span {
                    font-size: 10px;

                    &:first-child {
                        margin-right: 10px;
                        flex: 1 0 10%;
                        max-width: 10%;
                    }

                    &:nth-child(2) {
                        flex: 1 0 10%;
                        max-width: 10%;
                        margin-right: 10px;
                    }

                    &:nth-child(3) {
                        margin-right: 10px;
                        flex: 1 0 15%;
                        max-width: 15%;
                    }

                    &:nth-child(4) {
                        flex: 1 0 20%;
                        max-width: 20%;
                        margin-right: 10px;
                    }

                    &:last-child {
                        max-width: 42%;
                        flex: 1 0 42%;
                    }
                }
            }
        }

        .items-list-single {
            flex-flow: row wrap;

            .items-list-single-extra {
                flex-flow: row wrap;

                &__left {
                    flex: 1 0 100%;
                    max-width: 100%;
                    margin: 0 0 1rem 0;

                    .button-block {
                        margin-top: 1.5rem;
                    }
                }

                &__right {
                    flex: 1 0 100%;
                    max-width: 100%;
                }
            }

            &.event-item-single {
                .items-list-single-main {
                    padding-right: 0.5rem;

                    span {
                        &:first-child {
                            margin-right: 10px;
                            flex: 1 0 10%;
                            max-width: 10%;
                        }

                        &:nth-child(2) {
                            flex: 1 0 10%;
                            max-width: 10%;
                            margin-right: 10px;
                        }

                        &:nth-child(3) {
                            margin-right: 10px;
                            flex: 1 0 15%;
                            max-width: 15%;
                        }

                        &:nth-child(4) {
                            flex: 1 0 20%;
                            max-width: 20%;
                            margin-right: 10px;
                        }

                        &:nth-child(5) {
                            margin-right: 10px;
                        }
                    }

                    .text-h3 {
                        font-size: 11px;
                    }

                    .text-h4-alt {
                        font-size: 10px;
                    }
                }

                .items-list-single-extra {
                    padding-right: 0.5rem;
                    flex-flow: row wrap;

                    .item-image {
                        margin: 0 0 1rem 0;
                        flex: 1 0 100%;
                        max-width: 100%;
                    }

                    .item-description {
                        margin-right: 1.5rem;
                        flex: 1 0 60%;
                        max-width: 60%;
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .items-list.events-list .items-list-heading span {
        font-size: 8px;
    }
    .items-list .items-list-single.event-item-single .items-list-single-main .text-h3 {
        font-size: 10px;
    }
}

@media (max-width: 450px) {
    .items-list {
        &.events-list {
            margin: 0;

            .items-list-heading {
                display: none;
                visibility: hidden;
                padding: 0;
            }
        }

        .items-list-single {
            &.event-item-single {
                border-top: none;
                border-bottom: 1px solid $gray02;
                padding: 0.5rem 0;

                .items-list-single-main {
                    padding-right: 0.5rem;
                    border-top: none;
                    flex-flow: row wrap;
                    align-items: flex-start;
                    position: relative;

                    span {
                        &:first-child {
                            margin-right: 0;
                            flex: 1 0 calc(50% - 1.3rem);
                            max-width: calc(50% - 1.3rem);
                        }

                        &:nth-child(2) {
                            margin-right: 0;
                            flex: 1 0 calc(50% - 1.3rem);
                            max-width: calc(50% - 1.3rem);
                        }

                        &:nth-child(3) {
                            margin-right: 0;
                            flex: 1 0 calc(100% - 1.3rem);
                            max-width: calc(100% - 1.3rem);
                            margin-top: 10px;
                        }

                        &:nth-child(4) {
                            margin-right: 0;
                            flex: 1 0 calc(100% - 1.3rem);
                            max-width: calc(100% - 1.3rem);
                            margin-top: 10px;
                        }

                        &:nth-child(5) {
                            margin-right: 0;
                            flex: 1 0 calc(100% - 1.3rem);
                            max-width: calc(100% - 1.3rem);
                            margin-top: 10px;
                        }

                        &.toggle-event {
                            margin-top: 10px;
                            position: absolute;
                            right: 0;
                            top: 0;
                        }

                        .text-mobile-visible {
                            display: block;
                            max-width: 100%;
                            flex: 1 0 100%;
                            margin-right: 0;
                            font-size: 12px !important;
                            font-weight: bold;
                        }
                    }

                    .text-h3 {
                        font-size: 14px;
                    }

                    .text-h4-alt {
                        font-size: 14px;
                    }
                }

                .items-list-single-extra {
                    padding-right: 0.5rem;
                    flex-flow: row wrap;

                    .item-image {
                        order: 1;
                    }

                    .item-description {
                        margin-right: 0;
                        flex: 1 0 100%;
                        max-width: 100%;
                        font-size: 0.9rem;
                        margin-top: 10px;
                        order: 3;
                        padding-top: 0;
                    }

                    .item-info-2 {
                        order: 2;
                        flex: 1 0 100%;
                        max-width: 100%;
                        margin-top: 10px;
                        padding-top: 0;
                        text-align: left;

                        .item-info-row {
                            justify-content: flex-start;
                            text-align: left;

                            .item-info-left {
                                justify-content: flex-start;
                            }

                            .item-info-right {
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }
    }
}
