.homer-slider {
    .container {
        display: flex;
        flex-direction: column;
        position: relative;

        .black-bg {
            display: none;
        }
    }

    .bottom-link {
        margin-right: calc((100vw - 1216px) / 2);
        padding-top: 1.5rem;
        display: flex;
        justify-content: flex-end;
    }

    &.has-black-bg {
        padding-bottom: 0;

        .container {
            padding-bottom: 5rem;
            display: flex;
            flex-direction: column;
            position: relative;

            .black-bg {
                display: flex;
                height: 50%;
                background-color: #000;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        .bottom-link {
            z-index: 5;

            a {
                color: white;

                .arrow-right {
                    background-image: url(../img/icons/arrow-right-white.svg);
                }
            }
        }
    }
}
