.key-points-row {
    display: flex;
    flex-flow: row wrap;

    &__left {
        flex: 1 0 52.7%;
        max-width: 52.7%;
        margin-right: 0.89rem;

        .title-size-small {
            p {
                font-size: 85%;
            }
        }

        .title-size-medium {
            p {

            }
        }

        .title-size-big {
            p {
                font-size: 170%;
            }
        }

        .link-block {
            margin-top: 1.72rem;
        }
    }

    &__right {
        flex: 2;
        display: flex;
        flex-flow: row wrap;

        .one-point {
            flex: 1 0 calc(50% - 3.33rem);
            max-width: calc(50% - 3.33rem);
            margin: 0 0 3.33rem 3.33rem;
            position: relative;
            padding-left: 1.11rem;
            font-family: $notesEsa;
            text-transform: uppercase;
            font-size: 1.33rem;
            line-height: 1.2;
            font-weight: normal;

            p {
                font-family: $notesEsa;
                text-transform: uppercase;
                font-size: 1.33rem;
                line-height: 1.2;
                font-weight: normal;
            }

            &:before {
                content: '';
                width: 2px;
                height: 100%;
                left: 0;
                top: 0;
                background-color: $yellow;
                display: block;
                position: absolute;
            }

            strong {
                font-weight: normal;
                color: $yellow;
            }
        }
    }
}

@media (max-width: 899px) {
    .key-points-row {
        &__left {
            flex: 1 0 100%;
            max-width: 100%;
            margin: 0 0 3rem 0;
        }

        &__right {
            .one-point {
                margin: 0 3rem 3rem 0;
            }
        }
    }
}

@media (max-width: 499px) {
    .key-points-row {
        &__right {
            .one-point {
                margin: 0 0 1.5rem 0;
                flex: 1 0 100%;
                max-width: 100%;
            }
        }
    }
}
