//.modal-content {
//    box-shadow: 0 0 20px 7px #0000004d;
//}
//
//@media (max-width: 1150px) {
//    .modal-dialog {
//        &.modal-xl {
//            width: auto;
//            max-width: none;
//            margin: 0.5rem;
//        }
//    }
//}

.lyra-modal {
    .modal-content {
        box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.4);

        .modal-header {
            h4 {
                margin-bottom: 0;
            }
        }

        .modal-body {
            .modal-footer {
                padding-bottom: 1rem;
                margin: 1rem -1rem -1rem;
            }
        }
    }

    &.lyra-modal-progress {
        .modal-body {
            padding: 0 !important;

            .progress-wrap {
                .inner {
                    padding: 1rem 0;
                }

                .progress-line {
                    background-color: #0f1c26;
                    height: 2px;
                    transition: width .2s;
                }
            }
        }
    }

    &.lyra-modal-confirm {
        .btns-block {
            button {
                margin-right: .4rem;
            }
        }
    }

    &.lyra-modal-info {
        &.modal-sm {
            .modal-body {
                padding: 1.5rem;
            }
        }

        .modal-body {
            display: flex;
            flex-direction: row;

            .message-wrap {
                flex: 1;
                text-align: left;
            }

            .close-wrap {
                flex: 1;
                flex-grow: 0;

                .close {
                    padding: 0 .5rem;
                }
            }
        }
    }

    &.modal-xl {

        @media (min-width: 576px) {
            &.modal-dialog {
                max-width: none;
                margin: 0.5rem;
            }
        }

        @media (min-width: 992px) {
            &.modal-dialog {
                max-width: 90%;
                margin: 1.75rem auto;
            }
        }

        @media (min-width: 1200px) {
            &.modal-dialog {
                max-width: 70%;
                margin: 1.75rem auto;
            }
        }

    }

    &.modal-xxl {

        @media (min-width: 576px) {
            &.modal-dialog {
                max-width: none;
                margin: 0.5rem;
            }
        }

        @media (min-width: 992px) {
            &.modal-dialog {
                max-width: 90%;
                margin: 1.75rem auto;
            }
        }

        @media (min-width: 1200px) {
            &.modal-dialog {
                max-width: 90%;
                margin: 1.75rem auto;
            }
        }

    }
}
