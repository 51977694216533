p {
    font-weight: normal;
    font-family: Arial;
    font-size: 1rem;
    line-height: 1.5;
    color: $black;
    span {
        line-height: 1.2;
    }
}
h1 {
    font-size: 4rem;
    line-height: 1.19;
    margin: 0;
    padding: 0;
    color: $black;
    font-family: $notesEsa;
    text-transform: uppercase;
}
h2,
.text-h2 {
    @include text-h2;
    p {
        @include text-h2;
    }
}
.text-h2-bold {
    @include text-h2-bold;
    p {
        @include text-h2-bold;
    }
}
.text-h3,
h3 {
    @include text-h3;
    p {
        @include text-h3;
    }
}
h4 {
    @include text-h4;
    font-family: Arial;
    p {
        @include text-h4;
        font-family: Arial;
    }
}

.bg-yellow {
    @include bg-yellow;
}
.bg-gray {
    @include bg-yellow;
    background-color: $gray03;
    font-family: Arial;
    text-transform: none;
    letter-spacing: 0px;
    font-size: 1rem;
    margin: 0 8px 8px 0;
}

.text-h4-alt {
    @include text-h4;
    font-family: $notesEsa;
}
.text-h3-alt {
    @include text-h3-alt;
    p {
        @include text-h3-alt;
    }
}
.text-h2-home {
    @include text-h2-home;
}
.simple-link {
    color: $black;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 0.78rem;
    line-height: 1.3;
    font-family: $notesEsa;
    font-weight: normal;
    display: flex;
    align-items: center;
    transition: all 0.3s linear;
    span {
        margin-right: 1.1rem;
        border-bottom: 1px solid transparent;
        transition: all 0.3s linear;
    }
    &.white {
        color: $white;
    }
    &:hover,
    &:focus {
        outline: none;
        text-decoration: none;
        color: inherit;
        span {
            border-bottom-color: $yellow;
        }
        .esa-icon {
            background: url('../img/icons/arrow-right-yellow.svg') no-repeat 0 0 !important;
        }
    }
    .esa-icon.arrow-right {
        transition: all 0.3s linear;
    }
}
.text-h3-arial {
    font-family: Arial;
    font-size: 1.78rem;
    line-height: 1.52;
    font-weight: 400;
    text-transform: none;
}
@media (max-width: 899px) {
    h1 {
        font-size: 3.5rem;
    }
}
@media (max-width: 790px) {
    h1 {
        font-size: 2.5rem;
    }
    h2,
    .text-h2 {
        @include text-h2;
        font-size: 1.5rem;
        p {
            @include text-h2;
            font-size: 1.5rem;
        }
    }
    .text-h2-bold {
        @include text-h2-bold;
        font-size: 1.5rem;
        p {
            @include text-h2-bold;
            font-size: 1.5rem;
        }
    }
}
@media (max-width: 399px) {
    h1 {
        font-size: 2.2rem;
    }
    h2,
    .text-h2 {
        @include text-h2;
        font-size: 1.33rem;
        p {
            @include text-h2;
            font-size: 1.33rem;
        }
    }
    .text-h2-bold {
        @include text-h2-bold;
        font-size: 1.33rem;
        p {
            @include text-h2-bold;
            font-size: 1.33rem;
        }
    }
    .text-h3,
    h3 {
        font-size: 1.2rem;
        p {
            font-size: 1.2rem;
        }
    }
    .text-h3-alt {
        @include text-h3-alt;
        font-size: 1rem;
        p {
            @include text-h3-alt;
            font-size: 1rem;
        }
    }
}
