.main-header {
    position: relative;
}

.top-bar {
    padding: 0.89rem 4.44rem;
    background-color: $blue;
    border-bottom: 4px solid $yellow;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__left {
        display: flex;
        align-items: center;

        .search-block {
            margin: 0 1.17rem 0 0.89rem;

            span {
                color: $white;
                fill: $white;
            }
        }
    }
}

.navbar-toggle {
    display: none;
    vertical-align: middle;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #fff;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    text-shadow: none;
    z-index: 5000;
    width: auto;
    cursor: pointer;

    &:focus,
    &:hover {
        outline: none;
        box-shadow: none;
    }

    span {
        position: relative;
        left: 0;
        display: block;
        width: 18px;
        height: 2px;
        border-radius: 0;
        background: $white;
        transition: 0.5s all ease;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;

        &.nf {
            margin-top: 4px;
        }
    }

    &.active {
        span {
            width: 18px;
            height: 2px;
            top: 1px;
            left: 6px;

            &.nf {
                margin-top: 4px;
            }

            &:nth-child(1) {
                -webkit-transform: rotate(40deg);
                -moz-transform: rotate(40deg);
                -o-transform: rotate(40deg);
                transform: rotate(40deg);
            }

            &:nth-child(2) {
                width: 0;
                opacity: 0;
            }

            &:nth-child(3) {
                -webkit-transform: rotate(-40deg);
                -moz-transform: rotate(-40deg);
                -o-transform: rotate(-40deg);
                transform: rotate(-40deg);
            }
        }
    }
}

.main-navigation {
    position: sticky;
    top: 0;
    z-index: 1071;
    background-color: #fff;

    .main-navigation-top-wrap {
        padding: 1.89rem 0 1.67rem 0;
        border-bottom: 1px solid $gray10;
    }

    &__inside {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__left {
            .site-title {
                display: flex;
                flex-direction: column;

                .bold-text {
                    color: $black;
                    font-family: $notesEsa;
                    font-weight: bold;
                    font-size: 0.89rem;
                    line-height: 1.2;
                    letter-spacing: 1px;
                }

                .light-text {
                    color: $gray10;
                    font-weight: normal;
                    font-size: 0.67rem;
                    line-height: 1.2;
                    letter-spacing: 0.5px;
                }
            }
        }

        &__right {
            display: flex;
            align-items: center;

            .navigation {
                ul {
                    display: flex;
                    align-items: center;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        margin: 0 1.11rem;

                        a {
                            color: $black;
                            font-size: 1.11rem;
                            line-height: 1.4;
                            display: block;
                            text-transform: uppercase;
                            text-decoration: none;
                            font-family: $notesEsa;
                            font-weight: normal;
                            position: relative;

                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                left: 0;
                                bottom: -1.85rem;
                                width: 0;
                                height: 1px;
                                background-color: $black;
                                transition: width 0.3s linear;
                            }

                            &:hover,
                            &:focus {
                                &:after {
                                    width: 100%;
                                }
                            }
                        }

                        &.active {
                            a {
                                &:after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .search-block {
                /*margin-left: 1.44rem;*/
                display: none;
                visibility: hidden;
            }
        }
    }
}

.sub-navigation {
    //position: absolute;
    //left: 0;
    //top: 10.56rem;
    padding-top: 2rem;
    width: 100%;
    background-color: $white;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: height 0.3s linear;
    z-index: 899;

    &.opened {
        height: auto;
        opacity: 1;
        visibility: visible;
        padding-bottom: 2.22rem;
    }

    &__inside {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .container {
        height: 100%;
    }

    &.expand {
        .sub-navigation__top {
            .nav-column {
                .nav-list {
                    li {
                        ul {
                            height: auto;
                            visibility: visible;
                            opacity: 1;
                        }

                        &.has-subnav {
                            span {
                                &.active {
                                    .esa-icon {
                                        transform: rotate(90deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        padding-top: 1.17rem;
        padding-bottom: 8rem;

        .nav-column {
            h3 {
                display: inline-block;
            }

            .nav-list {
                list-style: none;
                padding: 0;
                margin: 15px 0 0 0;

                li {
                    margin: 5px 0;

                    a {
                        display: inline-block;
                        font-size: 0.89rem;
                        line-height: 1.4;
                        border-bottom: 1px solid transparent;
                        color: $black;
                        transition: all 0.3s linear;
                        &:hover,
                        &:focus {
                            color: $black;
                            border-bottom-color: $yellow;
                        }
                    }

                    span {
                        cursor: pointer;
                    }

                    ul {
                        height: 0;
                        opacity: 0;
                        visibility: hidden;
                        transition: height 0.3s linear;
                    }

                    &.has-subnav {
                        & > a {
                            position: relative;
                        }

                        &.opened {
                            ul {
                                visibility: visible;
                                height: auto;
                                opacity: 1;
                            }
                        }
                    }

                    .subnav-lvl2 {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        visibility: visible;
                        height: auto;
                        opacity: 1;

                        li {
                            a {
                                padding-left: 8px;
                                border-left: 1.5px solid $yellow;
                            }

                            .subnav-lvl3 {
                                list-style: none;
                                margin: 0 0 0 10px;
                                padding: 0;
                                visibility: visible;
                                height: auto;
                                opacity: 1;

                                li {
                                    a {
                                        padding-left: 8px;
                                        border-left: 1.5px solid $gray04;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .show-all {
        text-align: center;
        margin-bottom: 2.12rem;
        display: none;

        a {
            @include text-h4;
            font-family: $notesEsa;
            border-bottom: 1px solid $black;
            color: $black;
            transition: all 0.3s linear;
            &:hover,
            &:focus {
                color: $black;
                border-bottom-color: $yellow;
            }
        }
    }
}

@media (max-width: 1200px) {
    .top-bar {
        padding: 0.89rem 1rem;
    }
}

@media (max-width: 1600px) {
    .sub-navigation.opened {
        min-height: 20rem;
    }
}

@media (max-width: 899px) {
    .main-navigation {
        &__inside {
            &__left {
                flex: 1 0 30%;
                max-width: 30%;
            }

            &__right {
                .navigation {
                    ul {
                        li {
                            margin: 0 1rem;

                            a {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 750px) {
    .main-navigation {
        &__inside {
            &__right {
                .navigation {
                    ul {
                        li {
                            a {
                                .mobile-arrow {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-visible {
    display: none;
}

.mobile-hidden {
    display: block;
}

@media (max-width: 750px) {
    .mobile-visible {
        display: block;
    }
    .mobile-hidden {
        display: none;
    }
    .top-bar {
        padding: 14px 24px;
        border-bottom-color: #335e6f;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999999;

        &__left {
            .site-name {
                display: none;
            }
        }

        &__right {
            a {
                img {
                    height: 32px;
                    width: auto;
                }
            }
        }
    }
    .navbar-toggle {
        display: block;
    }
    .main-navigation {
        background-color: $white;
        display: none;
        top: 65px;

        &.mobile-shown {
            display: block;
        }

        .main-navigation-top-wrap {
            border-bottom: none;
            padding: 15px 0;
        }

        &__inside {
            &__left {
                display: none;
            }

            &__right {
                .search-block {
                    display: none;
                }

                .navigation {
                    ul {
                        display: block;

                        li {
                            margin: 5px 0;

                            a {
                                &:after {
                                    display: none;
                                }

                                display: flex;
                                align-items: center;

                                .mobile-arrow {
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    body.subnav-opened {
        overflow: hidden;
    }
    .sub-navigation {
        padding-top: 0;

        &.opened {
            height: calc(100vh - 9.2rem);
            position: fixed;
            min-height: 1rem;
            overflow-y: auto;
            bottom: 0;
            padding-top: 1rem;
        }

        &__inside {
            .show-all {
                display: none;
            }
        }

        &__top {
            display: block;
            padding-top: 0;
            padding-bottom: 2rem;

            .nav-column {
                .nav-list {
                    margin: 15px 0;

                    li {
                        display: flex;
                        align-items: center;
                        flex-flow: row wrap;

                        a {
                            display: block;

                            &:after {
                                position: absolute;
                                right: 10px;
                                top: 3px;
                            }
                        }

                        ul {
                            flex: 1 0 100%;
                        }
                    }
                }
            }
        }
    }
}
