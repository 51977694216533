.lyra-password-input {
    position: relative;

    .toggle-password {
        position: absolute;
        top: calc(50% - 8px);
        right: 10px;
        cursor: pointer;
    }

    .toggle-password-hidden::before {
        content: "\f070";
    }

    .toggle-password-shown::before {
        content: "\f06e";
    }

    .form-control {
        &.strength-1 {
            border-color: red !important;
        }

        &.strength-2 {
            border-color: orange !important;
        }

        &.strength-3 {
            border-color: green !important;
        }
    }
}
