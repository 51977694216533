.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3.94rem 0;
    li {
        margin: 0 5px;
        .page-link {
            border: none;
            padding: 0;
            color: $black;
            font-family: $notesEsa;
            font-weight: normal;
            font-size: 0.78rem;
            line-height: 1.3;
            letter-spacing: 1.5px;
            text-align: center;
            &:hover,
            &:focus {
                background-color: transparent;
                font-weight: bold;
                color: $black;
            }
        }
        &.active {
            .page-link {
                background-color: transparent;
                font-weight: bold;
                color: $black;
            }
        }
    }
}
