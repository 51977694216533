.main-footer {
    background-color: $blue;
    padding: 3.33rem 0;
    &__inside {
        display: flex;
        justify-content: space-between;
        &__left {
            display: flex;
            align-items: center;
            align-self: flex-start;
            .footer-logo {
                img {
                    width: 8.61rem;
                    height: auto;
                }
            }
            .site-title {
                display: flex;
                flex-direction: column;
                margin-left: 1.11rem;
                    p {
                        color: $white;
                        font-weight: normal;
                        font-size: 0.67rem;
                        line-height: 1.2;
                        letter-spacing: 0.5px;
                        margin:0;
                        strong {
                            color: $white;
                            font-family: $notesEsa;
                            font-weight: bold;
                            font-size: 0.89rem;
                            line-height: 1.2;
                            letter-spacing: 1px;
                        }
                    }
            }
        }
        &__right {
            align-self: flex-end;
            display: flex;
            flex-direction: column;
            padding-top: 1.22rem;
            ul {
                list-style: none;
                margin: 0 0 1.11rem 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                li {
                    margin: 0 0.69rem;
                    line-height: 1;
                    div {
                        line-height: 1;
                    }
                }
                &.footer-nav {
                    li {
                        p {
                            margin:0;
                            line-height: 1;
                            a {
                                color: $white;
                                text-transform: uppercase;
                                font-family: $notesEsa;
                                font-size: 0.67rem;
                                line-height: 1.2;
                                letter-spacing: 1.5px;
                                text-decoration: none;
                                border-bottom: none;
                                &:hover,
                                &:focus {
                                    color: $yellow;
                                }
                            }
                        }
                        &:last-child {
                            margin-right: 0;
                        }

                    }
                }
                &.social-nav {
                    li {
                        a {
                            color: #b9afa4;
                            text-decoration: none;
                            &:hover,
                            &:focus {
                                color: $yellow;
                                img {
                                    filter:none;
                                }
                            }
                            img {
                                height:15px;
                                width: auto;
                                filter: grayscale(0.9);
                                transition: all 0.3s liner;
                                margin-bottom: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.footer-mobile-visible {
    display: none;
    visibility: hidden;
}
@media (max-width: 760px) {
    .main-footer {
        &__inside {
            &__left {
                .footer-logo {
                    display: none;
                }
                .site-title {
                    margin-left: 0;
                }
            }
            &__right {
                padding-top: 0;
                .footer-nav {
                    display: none;
                }
                ul.social-nav {
                    li {
                        a {
                            color: $white;
                        }
                    }
                }
            }
        }
        .footer-mobile-visible {
            display: block;
            visibility: visible;
            margin-top: 46px;
            .footer-nav {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                li {
                    margin: 0 20px;
                    p {
                        a {
                            color: $white;
                            text-transform: uppercase;
                            font-family: $notesEsa;
                            font-size: 0.67rem;
                            line-height: 1.2;
                            letter-spacing: 1.5px;
                            text-decoration: none;
                            border-bottom: none;
                        }
                    }

                }
            }
        }
    }
}
