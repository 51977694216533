.block-two-one-column {
    padding-bottom: 5.22rem;

    &__inside {
        display: flex;
        justify-content: space-between;

        &__left {
            flex: 2;
        }

        &__right {
            flex: 1 0 30%;
            max-width: 30%;
            margin-left: 13%;
        }
    }
}

.block-centered {
    &__inside {
        margin: 0 17%;
    }
}

.block-centered-wider {
    &__inside {
        margin: 0 7% 0 17%;
    }
}

.social-share-block {

    .share-button {
        @include bg-yellow;
        display: inline-block;
        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        position: fixed;
        top: 30%;
        right: -51px;
        cursor: pointer;
    }

    .share-container {
        background-color: $white;
        padding: 2.22rem;
        box-shadow: 0px 24px 54px rgba(0, 0, 0, 0.2);
        margin-top: 1.8rem;
        transition: transfor 0.3s linear;
        transform: translateX(100%);
        position: fixed;
        top: 30%;
        right: 0;

        .share-container-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            letter-spacing: 1.66px;
            font-size: 0.86rem;
            line-height: 1.3;
            font-family: $notesEsa;
            font-weight: normal;
            text-transform: uppercase;

            .close-icon {
                cursor: pointer;
            }
        }

        .share-list {
            list-style: none;
            padding: 0;
            margin: 3.61rem 0;
            display: flex;

            li {
                margin: 0 1.11rem;

                a {
                    color: $black;
                    font-size: 2.22rem;

                    &:hover,
                    &:focus {
                        color: $yellow;
                    }
                }
            }
        }

        &.opened {
            transform: translateX(0);
        }
    }
}

.error-page {
    background-color: $black;
    height: 100%;

    .error-page-container {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 8rem 0 0 0;
        text-align: center;

        .error-page-container-inside {
            flex: 1 0 100%;
            max-width: 100%;

            h1 {
                color: $white;
                font-family: $notesEsa;
                font-size: 1.33rem;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                .text-yellow {
                    font-size: 5.33rem;
                    margin-right: 2.22rem;
                }

                .text-smaller {

                }
            }

            .link-block {
                text-align: center;
                margin-top: 2.22rem;

                .esa-icon.arrow-right {
                    background: url('../img/icons/arrow-right-white.svg') no-repeat 0 0;
                }

                a {
                    &:hover,
                    &:focus {
                        .esa-icon.arrow-right {
                            background: url('../img/icons/arrow-right.svg') no-repeat 0 0;
                        }
                    }
                }
            }

            .image-block {
                margin-top: 2.22rem;
            }
        }
    }
}

@media (max-width: 750px) {
    .app-content {
        padding-top: 3rem;
    }
    .error-page {
        .error-page-container {
            padding: 8rem 0 0 0;

            .error-page-container-inside {
                h1 {
                    flex-flow: row wrap;

                    span {
                        flex: 1 0 100%;
                        max-width: 100%;

                        &.text-yellow {
                            margin-right: 0;
                        }

                        &.text-smaller {
                            margin-top: 1.33rem;
                        }
                    }
                }
            }
        }
    }
}
