html {
    font-size: 18px;
}
body {
    margin: 0 auto;
    font-size: 1rem;
    font-weight: normal;
    font-family: Arial;
    line-height: 1.5;
    color: $black;
}

.container {
    margin: 0 auto;
    max-width: 1216px;
    padding-left: 0;
    padding-right: 0;
}

a {
    text-decoration: none;
    transition: all 0.3s linear;
    &:hover,
    &:focus {
        outline: none;
        text-decoration: none;
    }
}
p {
    a {
        border-bottom: 2px solid $black;
        color: $black;
        &:hover,
        &:focus {
            color: $black;
            border-bottom-color: $yellow;
        }
    }
}
img {
    max-width: 100%;
    height: auto;
}
.ptb-1 {
    padding: 3.33rem 0;
}
.black-background {
    background-color: $black;
}
.list-with-arrows {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        margin-bottom: 1.11rem;
        position: relative;
        padding-left: 1.44rem;
        line-height: 1.17;
        &:before {
            content: '\f105';
            font-family: 'FontAwesome';
            position: absolute;
            left: 4px;
            top: 0;
            font-size: 14px;
        }
        a {
            color: $black;
        }
        &.button-element {
            padding-left: 0;
            &:before {
                display: none;
            }
        }
    }
}
.tags-block {
    margin: 1.55rem 0 2.22rem 0;
    .tags-list {
        display: flex;
        flex-flow: row wrap;
        margin-top: 1.11rem;
    }
}

.underline {
    text-decoration: underline;
}
.color-white {
    color: $white;
}
.text-yellow {
    color: $yellow;
}
.align-right {
    text-align: right;
}
hr {
    margin: 1.11rem 0;
    border-top: 2px solid $yellow;
}
.title-with-border {
    position: relative;
    padding-left: 1.56rem;
    &:before {
        content: '';
        width: 8px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $yellow;
        display: block;
        position: absolute;
    }
}
.title-with-arrow {
    position: relative;
    display: flex;
    align-items: center;
    i {
        margin-right: 10px;
        padding: 0 5px;
    }
    a {
        border-bottom: 2px solid transparent;
        transition: all 0.3s linear;
        &:hover,
        &:focus {
            border-bottom-color: $yellow;
        }
    }
}
.two-cover-blocks {
    display: flex;
    margin: 0 -2rem;
    flex-flow: row wrap;
    .one-block {
        flex: 1 0 calc(50% - 4rem);
        max-width: calc(50% - 4rem);
        margin: 0 2rem;
        min-height: 19rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        padding: 2.22rem 3.78rem 2.22rem 2.22rem;
        h3 {
            font-size: 2rem;
            line-height: 1.2;
        }
    }
}
.image-background-section {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 8.89rem 0 9.5rem;
    position: relative;
}

.content-modules-wrapper {
    padding: 2.22rem 0;

    &.content-module-type-Quote,
    &.content-module-type-Text {
        padding: 1.5rem 0;
    }
    &.color-palette-light {
    }
    &.color-palette-dark {
        color: $white;
        &.collapsible {
            .complex-list__item__content ul li {
                border-bottom-color:$white;
            }
        }
        h1,
        .text-h3-alt,
        h2,
        .text-h2,
        .text-h2-bold,
        h3,
        .text-h3,
        .text-h3-alt,
        h4,
        .text-h4-alt,
        p {
            color: $white;
            a {
                border-bottom: 2px solid $white;
                color: $white;
                &:hover,
                &:focus {
                    color: $white;
                    border-bottom-color: $yellow;
                }
            }
        }
        ul {
            li {
                color: $white;
                a {
                    color: $white;
                }
            }
        }
        .simple-link {
            color: $white;
            .esa-icon {
                &.arrow-right {
                    background: url('../img/icons/arrow-right-white.svg') no-repeat 0 0;
                }
            }
        }
        .button {
            color: $white;
            border-color: $white;
            .esa-icon {
                &.arrow-right {
                    background: url('../img/icons/arrow-right-white.svg') no-repeat 0 0;
                }
            }
            &:hover,
            &:focus {
                color: $black;
                background-color: $white;
                .esa-icon {
                    &.arrow-right {
                        background: url('../img/icons/arrow-right.svg') no-repeat 0 0;
                    }
                }
            }
        }
        .text-yellow {
            color: $yellow;
        }
        .person-block .text-block .person-contact a {
            color: $white;
        }
        .title-with-arrow {
            .esa-icon {
                &.arrow-right {
                    background: url('../img/icons/arrow-right-white.svg') no-repeat 0 0;
                }
            }
        }
        &.content-module-type-Links {
            &.font-size-BIG {
                .services-list {
                    .one-list-block {
                        ul {
                            li {
                                &:before {
                                    background: url('../img/icons/arrow-right-white.svg') no-repeat 0 0;
                                    transition: all 0.3s linear;
                                }
                                &:hover,
                                &:focus {
                                    &:before {
                                        background: url('../img/icons/arrow-right-yellow.svg') no-repeat 0 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .cmt-table-wrap {
            .cmt-row {
                .cmt-column {
                    border-bottom: 1px solid rgba($white, 0.5) !important;
                }
            }
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            margin-bottom: 1.11rem;
            position: relative;
            padding-left: 1.44rem;
            line-height: 1.17;
            &:before {
                content: '\f105';
                font-family: 'FontAwesome';
                position: absolute;
                left: 4px;
                top: 0;
                font-size: 14px;
            }
            a {
                color: $black;
                transition: all 0.3s linear;
                &:hover,
                &:focus {
                    color: $yellow;
                }
            }
        }
    }
    &.has-displaced-image {
        position: relative;
        margin-top: 17rem;
        padding-top: 17rem;
        .displaced-image {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: translateY(-50%);
            height: 26rem;
            top: 0;
            width: 100%;
            text-align: center;
            max-width: 1216px;
        }
        &.displaced-image-left {
            .displaced-image {
                text-align: left;
            }
        }
        &.displaced-image-right {
            .displaced-image {
                text-align: right;
            }
        }
    }
    &.half-width {
        .container {
            & > div {
                max-width: 50%;
            }
        }
    }
}
span.link {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.post-preview-media {
    padding-top: 10px;

    &.post-preview-image {
        text-align: center;
    }

    &.post-preview-video {
        video {
            max-width: 100%;
        }
    }
}

label.required {
    &:before {
        content: '*';
        color: red;
        padding-right: 0.3rem;
    }
}

#app-404,
#app-error {
    display: none;
}
@media (max-width: 1200px) {
    .container {
        max-width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
    }
    .content-modules-wrapper {
        &.half-width {
            .container {
                & > div {
                    max-width: 70%;
                }
            }
        }
    }
}
@media (max-width: 1000px) {
    html {
        font-size: 16px;
    }
}
@media (max-width: 899px) {
    .content-modules-wrapper {
        &.half-width {
            .container {
                & > div {
                    max-width: 100%;
                }
            }
        }
        &.has-displaced-image {
            margin-top: 14rem;
            padding-top: 11rem;
            .displaced-image {
                height: 16rem;
            }
        }
    }
    .page-intro {
        .content-modules-wrapper {
            &.has-displaced-image {
                margin-top: 1rem;
                padding-top: 0;
                .displaced-image {
                    position: initial;
                    transform: none;
                    height: auto;
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .content-modules-wrapper {
        &.has-displaced-image {
            margin-top: 10rem;
            padding-top: 9rem;
            .displaced-image {
                height: 12rem;
            }
        }
    }
}
@media (max-width: 599px) {
    .title-with-arrow {
        .text-h3 {
            font-size: 0.98rem;
            p {
                font-size: 0.98rem;
            }
        }
    }
}
@media (max-width: 499px) {
    .content-modules-wrapper {
        padding: 1.11rem 0;
    }
}
@media (max-width: 399px) {
    .content-modules-wrapper {
        &.has-displaced-image {
            margin-top: 6rem;
            padding-top: 5rem;
            .displaced-image {
                height: 8rem;
            }
        }
    }
}
