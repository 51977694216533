.text-two-columns {
    padding: 0;
    &__title {
        max-width: 50%;
        h1 {
            margin-bottom: 2.22rem;
        }
        h3 {
            margin-bottom: 2.22rem;
        }
    }
    &__content {
        display: flex;
        justify-content: space-between;
        &__left {
            flex: 2;

            .image-block {
                margin-bottom: 2.22rem;
            }
        }
        &__right {
            flex: 1 0 40%;
            max-width: 40%;
            margin-left: 1.11rem;
        }
    }
}
@media (max-width: 1100px) {
    .text-two-columns {
        &__title {
            max-width: 70%;
        }
    }
}
@media (max-width: 899px) {
    .text-two-columns {
        padding: 0;
        &__title {
            max-width: 100%;
        }
    }
}
@media (max-width: 790px) {
    .text-two-columns {
        &__content {
            flex-flow: row wrap;
            &__right {
                flex: 1 0 100%;
                max-width: 100%;
                margin: 1rem 0 0 0;
            }
        }
    }
}
@media (max-width: 760px) {
    .text-two-columns {
        padding: 0;
        &__content {
            &__left {
                .image-block {
                    img {
                        max-width: initial;
                        width: 100%;
                    }
                }
            }
        }
    }
}
