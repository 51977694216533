.two-blocks {
    display: flex;
    margin: 0 -1.11rem;
    & > div {
        flex: 1 0 calc(50% - 2.22rem);
        max-width: calc(50% - 2.22rem);
        margin: 0 1.11rem;
        position: relative;
        overflow: hidden;
        background-size: cover;
        transition: all 0.3s linear;
        background-color: $white;
        &:hover {
            box-shadow: 0px 50px 200px rgba(255, 255, 255, 0.2);
        }
    }
    .content-block {
        padding: 4.44rem 3.33rem;
        min-height: 30.7rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .text-h2 {
            margin-bottom: 2.22rem;
            p {
                @include text-h2;
            }
        }
        .text-h3 {
            margin-bottom: 2.22rem;
            p {
                @include text-h3;
            }
        }
        .link-block {
            margin-top: 2.39rem;

            a {
                display: flex;
                align-items: center;
                color: $black;
                font-weight: normal;
                font-size: 0.78rem;
                line-height: 1.3;
                letter-spacing: 1.5px;
                text-transform: uppercase;
                font-family: $notesEsa;
                span {
                    border-bottom: 1px solid transparent;
                    transition: all 0.3s linear;
                }
                &:hover,
                &:focus {
                    outline: none;
                    text-decoration: none;
                    color: inherit;
                    span {
                        border-bottom-color: $yellow;
                    }
                    .esa-icon {
                        background: url('../img/icons/arrow-right-yellow.svg') no-repeat 0 0 !important;
                    }
                }
                .esa-icon {
                    margin-left: 1rem;
                    transition: all 0.3s linear;
                }
            }
        }
        .simple-text {
            font-size: 1rem;
            line-height: 1.5;
            p {
                font-size: 1rem;
                line-height: 1.5;
            }
        }
        .person-block {
            margin-top: 4rem;
        }
        &.color-palette-dark {
            .text-h2 {
                color: $white;
                p {
                    @include text-h2;
                    color: $white;
                }
            }
            .text-h3 {
                color: $white;
                p {
                    @include text-h3;
                    color: $white;
                }
            }
            .simple-text {
                color: $white;
                p {
                    color: $white;
                }
            }
            .link-block {
                a {
                    color: $white;
                }
            }
            .person-block {
                .text-block {
                    h4 {
                        color: $white;
                    }

                    .small-text {
                        color: $white;
                    }

                    .person-contact {
                        a {
                            color: $white;
                        }
                    }
                }
            }
        }
        &.color-palette-light {
            .link-block {
                .esa-icon {
                    &.arrow-right-white {
                        background-image: url('../img/icons/arrow-right.svg');
                    }
                }
            }
            .text-h2 {
                color: $black;
                p {
                    @include text-h2;
                    color: $black;
                }
            }
            .text-h3 {
                color: $black;
                p {
                    @include text-h3;
                    color: $black;
                }
            }
            .simple-text {
                color: $black;
                p {
                    color: $black;
                }
            }
            .link-block {
                a {
                    color: $black;
                }
            }
            .person-block {
                .text-block {
                    h4 {
                        color: $black;
                    }

                    .small-text {
                        color: $black;
                    }

                    .person-contact {
                        a {
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}
.smaller-height {
    .two-blocks {
        .content-block {
            padding: 2.22rem 3.78rem 2.22rem 2.22rem;
            min-height: 19rem;
            justify-content: space-between;
        }
    }
}
@media (max-width: 1200px) {
    .two-blocks {
        .content-block {
            padding: 2.44rem 2.33rem;
            min-height: 29rem;
        }
    }
}
@media (max-width: 890px) {
    .two-blocks {
        .content-block {
            padding: 2rem;
            min-height: 22rem;
            .text-h2 {
                margin-bottom: 1.11rem;
                font-size: 1.4rem;
                p {
                    font-size: 1.4rem;
                }
            }
            .text-h3 {
                margin-bottom: 1.11rem;
                font-size: 1.2rem;
                p {
                    font-size: 1.2rem;
                }
            }
            .link-block {
                margin-top: 1.39rem;
            }
            &.color-palette-light {
                .text-h2 {
                    font-size: 1.4rem;
                    p {
                        font-size: 1.4rem;
                    }
                }
                .text-h3 {
                    font-size: 1.2rem;
                    p {
                        font-size: 1.2rem;
                    }
                }
            }
            &.color-palette-dark {
                .text-h2 {
                    font-size: 1.4rem;
                    p {
                        font-size: 1.4rem;
                    }
                }
                .text-h3 {
                    font-size: 1.2rem;
                    p {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}
@media (max-width: 760px) {
    .two-blocks {
        flex-flow: row wrap;
        margin: 0;
        & > div {
            flex: 1 0 100%;
            max-width: 100%;
            margin: 0 0 1.11rem 0;
        }
    }
}
@media (max-width: 399px) {
    .two-blocks {
        .content-block {
            padding: 1.44rem 1.33rem;
            min-height: 15rem;
            .person-block {
                margin-top: 2rem;
            }
        }
    }
}
