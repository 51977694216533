$black: #000;
$white: #fff;
$blue: #003247;
$yellow: #fda729;
$yellow01: #e1972a;
$gray: #828282;
$gray01: #f4f4f4;
$gray02: #d5d6d2;
$gray03: #f1f1f1;
$gray04: #c4c4c4;
$gray05: #aeaeae;
$gray06: #a1b0b5;
$gray09: #333333;
$gray10: #777777;
$red: #fd2929;

$notesEsa: 'NotesEsa';
