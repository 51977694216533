.quote-block {
    display: flex;
    &__image {
        width: 4.44rem;
        height: 4.44rem;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        flex: 1 0 4.44rem;
        max-width: 4.44rem;
        margin-right: 1.78rem;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    &__content {
        flex-grow: 2;
        padding-right: 4.11rem;
        position: relative;
        &:after {
            content: '';
            background: url('../img/icons/quote.svg') no-repeat 0 0;
            width: 55px;
            height: 50px;
            background-size: contain;
            display: block;
            position: absolute;
            right: 0;
            top: 0;
        }
        .quote-autor {
            font-weight: bold;
            p {
                font-weight: bold;
            }
        }
    }
    &.without-image {
        quote-block__image {
            display: none;
            visibility: hidden;
        }
        .quote-block__content {
            padding: 0 0 0 1rem;
            border-left: 8px solid $yellow;
            display: flex;
            align-items: center;
            min-height: 4rem;
            .quote-text {
                p {
                    margin: 0;
                    font-family: $notesEsa;
                    font-weight: normal;
                    font-size: 1.5rem;
                    line-height: 1.18;
                    text-transform: uppercase;
                }
            }

            &:after {
                display: none;
            }
        }
    }
}
@media (max-width: 499px) {
    .quote-block {
        flex-flow: row wrap;
        &__image {
            flex: 1 0 5rem;
            max-width: 5rem;
            margin-right: 0;
            width: 5rem;
            height: auto;
            margin-bottom: 1rem;
        }
        &__content {
            padding-right: 40px;
            flex: 1 0 100%;
            max-width: 100%;
            &:after {
                width: 30px;
                height: 30px;
                background-size: contain;
            }
        }
    }
}
