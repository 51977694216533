.big-list-2-wrap {

    .big-list-2-item-wrap {
        padding: 1rem 2rem;

        .big-list-2-item-inner {
            border-top: 1px solid #dfdfdf;
            display: flex;
            flex-direction: row;
            padding-top: .5rem;

            .box-1 {
                width: 45%;
                display: flex;
                flex-direction: column;

                .title-wrap {
                    flex: 1;
                }

                .button-wrap {
                    padding-top: .5rem;
                }
            }

            .box-2 {
                width: 30%;
                font-size: .8rem;
                font-family: 'NotesEsa';

                .title {
                    color: #777777;
                    text-transform: uppercase;
                    margin-bottom: .5rem;
                }

                .items-wrap {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    .item {
                        background-color: #F5F5F5;
                        text-transform: uppercase;
                        margin-right: .5rem;
                        margin-bottom: .5rem;
                        padding: .2rem .5rem;
                    }
                }
            }

            .box-3 {
                width: 25%;
            }

        }
    }

    @media (max-width: 599px) {
        .big-list-2-item-wrap {
            .big-list-2-item-inner {
                flex-direction: column;

                .box-1 {
                    width: 100%;
                    margin-bottom: 1rem;

                    .button-wrap {
                        .link-block {
                            a {
                                width: 100%;
                                justify-content: center;
                                padding-top: 4px;
                                padding-bottom: 4px;
                            }
                        }
                    }
                }

                .box-2 {
                    width: 100%;
                }

                .box-3 {
                    width: 100%;
                }
            }
        }
    }

}
