.modal-backdrop {
    z-index: 1075;
    &.show {
        opacity: 0.8;
    }
}
.modal {
    z-index: 1085;
    .modal-dialog {
        &.modal-video {
            max-width: 70%;
            .modal-content {
                padding: 0 2rem 2rem;
                background-color: $black;
                .modal-header {
                    .btn-close {
                        color: $white;
                        opacity: 1;
                        text-shadow: none;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        z-index: 10;
                        right: 5px;
                        top: 5px;
                        padding: 0;
                        border:none;
                        background: transparent;
                        &:after,
                        &:before {
                            position: absolute;
                            right: 10px;
                            content: ' ';
                            height: 20px;
                            width: 2px;
                            background-color: $white;
                            top: 0;
                        }

                        &:before {
                            transform: rotate(45deg);
                        }
                        &:after {
                            transform: rotate(-45deg);
                        }
                    }
                }
                .modal-body {
                    position: relative;
                    overflow: hidden;
                    padding-top: 56.25%;
                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: 0;
                    }
                }
            }
        }
        &.modal-image {
            max-width: 80%;
            border: none;
            .modal-content {
                padding: 0 2rem 2rem;
                background-color: $black;
                box-shadow: none;
                border: none;
                .modal-header {
                    padding: 0;
                    border: none;
                    .btn-close {
                        color: $white;
                        opacity: 1;
                        text-shadow: none;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        z-index: 10;
                        right: 5px;
                        top: 5px;
                        padding: 0;
                        border:none;
                        background: transparent;
                        &:after,
                        &:before {
                            position: absolute;
                            right: 10px;
                            content: ' ';
                            height: 20px;
                            width: 2px;
                            background-color: $white;
                            top: 0;
                        }

                        &:before {
                            transform: rotate(45deg);
                        }
                        &:after {
                            transform: rotate(-45deg);
                        }
                    }
                }
                .modal-body {
                    padding: 0;
                    text-align: center;
                    .caption {
                        color: $white;
                        font-family: $notesEsa;
                        font-size: 14px;
                        letter-spacing: 1.5px;
                        margin-top: 10px;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .modal-body {
            .form {
                max-width: 50%;
                margin: 0 auto;
                padding: 3.33rem 0 11.11rem 0;
            }
        }
    }
}
.contact-modal-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.22rem 8% 2.22rem 8%;
    .logo-left {
        .site-title {
            display: flex;
            flex-direction: column;

            .bold-text {
                color: $black;
                font-family: $notesEsa;
                font-weight: bold;
                font-size: 0.89rem;
                line-height: 1.2;
                letter-spacing: 1px;
            }

            .light-text {
                color: $gray10;
                font-weight: normal;
                font-size: 0.67rem;
                line-height: 1.2;
                letter-spacing: 0.5px;
            }
        }
    }
}
.contact-modal-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8% 1.11rem 8%;
    border-bottom: 1px solid $gray05;
    &__center {
        display: flex;
        align-items: center;
        .message-label {
            font-size: 0.89rem;
            font-weight: normal;
            color: $gray06;
            margin-right: 10px;
        }
        .person-block {
            margin-top: 0;
        }
    }
    &__right {
        max-width: 50%;
    }
}
@media (max-width: 1100px) {
    .modal {
        .modal-dialog {
            .modal-body {
                .form {
                    max-width: 90%;
                }
            }
        }
    }
    .contact-modal-info {
        padding-left: 0;
        padding-right: 0;
    }
    .contact-modal-top {
        padding-left: 0;
        padding-right: 0;
    }
}
@media (max-width: 760px) {
    .contact-modal-top {
        padding: 1rem 0;
    }
    .contact-modal-info {
        flex-direction: column;
        & > div {
            padding: 10px 0;
        }
        &__right {
            max-width: 100%;
            text-align: center;
        }
    }
    .modal {
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 4rem;
        .modal-dialog {
            margin: 0 auto;
            .modal-body {
                .form {
                    max-width: 100%;
                    padding: 1rem 0 2rem 0;
                }
            }
        }
    }
}
