@font-face {
    font-family: 'NotesEsa';
    src: url('../fonts/NotesEsa-Bold.eot');
    src: url('../fonts/NotesEsa-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/NotesEsa-Bold.woff2') format('woff2'),
        url('../fonts/NotesEsa-Bold.woff') format('woff'), url('../fonts/NotesEsa-Bold.ttf') format('truetype'),
        url('../fonts/NotesEsa-Bold.svg#NotesEsa-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'NotesEsa';
    src: url('../fonts/NotesEsa.eot');
    src: url('../fonts/NotesEsa.eot?#iefix') format('embedded-opentype'), url('../fonts/NotesEsa.woff2') format('woff2'),
        url('../fonts/NotesEsa.woff') format('woff'), url('../fonts/NotesEsa.ttf') format('truetype'),
        url('../fonts/NotesEsa.svg#NotesEsa') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NotesEsa';
    src: url('../fonts/NotesEsa-BoldItalic.eot');
    src: url('../fonts/NotesEsa-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/NotesEsa-BoldItalic.woff2') format('woff2'),
        url('../fonts/NotesEsa-BoldItalic.woff') format('woff'), url('../fonts/NotesEsa-BoldItalic.ttf') format('truetype'),
        url('../fonts/NotesEsa-BoldItalic.svg#NotesEsa-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'NotesEsa';
    src: url('../fonts/NotesEsa-Italic.eot');
    src: url('../fonts/NotesEsa-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/NotesEsa-Italic.woff2') format('woff2'),
        url('../fonts/NotesEsa-Italic.woff') format('woff'), url('../fonts/NotesEsa-Italic.ttf') format('truetype'),
        url('../fonts/NotesEsa-Italic.svg#NotesEsa-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}
