.home-nav {
    background-color: $black;
    padding: 3.33rem 0 2.44rem 0;
    &__inside {
        display: flex;
    }
    &__block {
        &:not(:last-child) {
            margin-right: 4.5rem;
            max-width: 18%;
            flex: 1 0 18%;
        }
        h3 {
            margin-bottom: 2.22rem;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                margin-bottom: 0.89rem;
                a {
                    color: $white;
                    text-decoration: none;
                    font-size: 0.78rem;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    letter-spacing: 1px;
                    .esa-icon {
                        margin-right: 12px;
                        flex: 1 0 24px;
                        max-width: 24px;
                    }
                }
            }
        }
        .bg-yellow {
            &:last-of-type {
                margin-top: 10px;
                margin-bottom: 15px;
                display: inline-block;
            }
        }
    }
}
.home-agenda {
    padding-top: 13.33rem;
    .home-agenda-inside {
        position: relative;
        padding-left: 1.56rem;
        margin-bottom: 1.72rem;
        &:before {
            content: '';
            width: 8px;
            height: 100%;
            left: 0;
            top: 0;
            background-color: $yellow;
            display: block;
            position: absolute;
        }
    }
}
.home-strategy {
    padding: 10.61rem 0 13.33rem;
    display: flex;
    flex-flow: row wrap;
    &__left {
        flex: 1 0 52.7%;
        max-width: 52.7%;
        margin-right: 0.89rem;
        .link-block {
            margin-top: 1.72rem;
        }
    }
    &__right {
        flex: 2;
        display: flex;
        flex-flow: row wrap;
        .one-strategy {
            flex: 1 0 calc(50% - 3.33rem);
            max-width: calc(50% - 3.33rem);
            margin: 0 0 3.33rem 3.33rem;
            position: relative;
            padding-left: 1.11rem;
            color: $white;
            &:before {
                content: '';
                width: 2px;
                height: 100%;
                left: 0;
                top: 0;
                background-color: $yellow;
                display: block;
                position: absolute;
            }
            span {
                font-family: $notesEsa;
                text-transform: uppercase;
                font-size: 1.33rem;
                line-height: 1.2;
                font-weight: normal;
            }
        }
    }
    .text-h3-alt {
        color: $white;
        font-size: 2rem;
    }
}
.create-space-mission {
    padding: 18.89rem 0 19.89rem;
    &__inside {
        .title-with-border {
            margin-bottom: 3.33rem;
            .text-h2-home {
                color: $black;
            }
        }
    }
}
.home-video {
    padding: 12.61rem 0 11.28rem 0;
    &__inside {
        min-height: 41rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5.83rem 0 5.72rem;
        h2 {
            flex: 1 0 45%;
            max-width: 45%;
            margin-right: 15rem;
        }
    }
}
