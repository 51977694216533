.general-articles-slider {
    padding: 4rem 0;
    .container {
        max-width: 100%;
        overflow: hidden;
        padding-left: calc((100vw - 1216px) / 2);
    }
    .slider-title {
        margin-bottom: 0;
        @include bg-yellow;
        display: inline-block;
    }
    .slider-arrows {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 1.11rem;
        margin-right: calc((100vw - 1216px) / 2);
        a {
            background: url('../img/icons/arrow-right.svg') no-repeat 0 0;
            width: 25px;
            height: 14px;
            background-size: contain;
            text-indent: -9999px;
            display: block;
            margin: 0 1.83rem;
            transition: all 0.3s linear;
            &:first-child {
                transform: rotate(-180deg);
            }
            &:hover,
            &:focus {
                background: url('../img/icons/arrow-right-yellow.svg') no-repeat 0 0 !important;
            }
        }
    }
    .slick-track {
        display: flex !important;
    }
    .slide {
        &__inside {
            padding: 8.88rem 0 3.33rem 5.55rem;
            min-height: 31rem;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            margin: 0 2.22rem 0 0;
        }
        &__content {
            background-color: $black;
            padding: 1.78rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 18.9rem;
            &__top {
                .text-date {
                    @include text-h4;
                    color: $white;
                    font-family: $notesEsa;
                    margin-bottom: 10px;
                    p {
                        @include text-h4;
                        color: $white;
                        font-family: $notesEsa;
                        margin-bottom: 0;
                    }
                }
                h3 {
                    color: $white;
                    margin-bottom: 10px;
                    p {
                        color: $white;
                        margin-bottom: 0;
                    }
                }
                .text-subtitle {
                    font-size: 0.89rem;
                    color: $white;
                    margin: 0;
                    p {
                        font-size: 0.89rem;
                        color: $white;
                        margin: 0;
                    }
                }
            }
            &__bottom {
                .text-tags {
                    color: $yellow;
                    text-transform: uppercase;
                    font-size: 11px;
                    line-height: 1;
                    letter-spacing: 1px;
                    text-align: right;
                }
                .link-block {
                    margin-top: 2.28rem;
                    a {
                        color: $white;
                        .esa-icon {
                            &.arrow-right {
                                background: url('../img/icons/arrow-right-white.svg') no-repeat 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1600px) {
    .general-articles-slider {
        .slide__inside {
            padding: 6.5rem 0 1.5rem 2.5rem;
            min-height: 28rem;
        }
    }
}
@media (max-width: 1350px) {
    .general-articles-slider {
        .slide__inside {
            padding: 8.88rem 0 3.33rem 5.55rem;
            min-height: 28rem;
        }
    }
}
@media (max-width: 1100px) {
    .general-articles-slider {
        .container {
            padding-left: 1rem;
            padding-right: 0;
        }
        .slider-arrows {
            margin-right: 0;
        }
        .slide__inside {
            padding: 6.5rem 0 1.5rem 2.5rem;
            min-height: 26rem;
            margin-right: 1.5rem;
        }
    }
}

@media (max-width: 499px) {
    .general-articles-slider {
        .slide {
            &__inside {
                padding: 8.88rem 0 3.33rem 5.55rem;
                min-height: 28rem;
                margin-right: 1rem;
            }
        }
    }
}
@media (max-width: 365px) {
    .general-articles-slider {
        .slide {
            &__inside {
                padding: 8.88rem 0 3.33rem 3.55rem;
                min-height: 28rem;
                margin-right: 1rem;
            }
        }
    }
}
