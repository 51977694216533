.home-hero-block {
    padding: 5.83rem 0 10.22rem 0;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: auto;
    position: relative;
    z-index: 10;
    .hero-video {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        max-height: 28rem;
        height: 100%;
        background-repeat: no-repeat;
        z-index: 10;
        overflow: hidden;
        video {
            min-width: 60%;
            min-height: 100%;
            width:70%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0,-55%);
        }
    }
    &__inside {
        display: flex;
        justify-content: center;
        align-items: center;
        &__left {
            position: relative;
            z-index: -1;
        }
        &__right {
            flex: 1 0 44%;
            max-width: 44%;
            padding-left: 1.11rem;
            position: relative;
            margin-left: auto;
            z-index: 20;
            h1 {
                text-transform: uppercase;
                font-size: 3.33rem;
                line-height: 1.16;
                font-weight: normal;
            }
        }
    }
}

@media (max-width: 1500px) {
    .home-hero-block {
        padding: 5.83rem 0 7.22rem 0;
        .hero-video {

        }
    }
}

@media (max-width: 1100px) {
    .home-hero-block {
        padding: 3.83rem 0 4.22rem 0;
        .hero-video {
            video {
                width: 70%;
                transform: translate(-4%, -50%);
            }
        }
        &__inside {
            &__right {
                flex: 1 0 50%;
                max-width: 50%;
            }
        }
    }
}

@media (max-width: 899px) {
    .home-hero-block {
        padding: 2rem 0 6.22rem 0;
        .hero-video {
            position: initial;
            height:auto;
            video {
                transform: translate(0, 0);
                width:100%;
            }
        }
        &__inside {
            flex-flow: row wrap;
            &__right {
                flex: 1 0 100%;
                max-width: 100%;
                text-align: center;
                padding-top: 2rem;
                padding-left: 0;
                margin-left: 0;
                &:before {
                    height: 1px;
                    width: 90%;
                    left: 5%;
                    top: 0;
                }
                .search-input {
                    margin:0 auto;
                }
                .suggested-search {
                    .suggested-search-list {
                        justify-content: center;
                    }
                }
            }
        }
    }
}
@media (max-width: 790px) {
    .home-hero-block__inside__right h1 {
        font-size: 2.8rem;
    }
}
@media (max-width: 399px) {
    .home-hero-block__inside__right h1 {
        font-size: 2.5rem;
    }
    .home-hero-block {
        padding:2rem 0 11.22rem 0;
        .hero-video {
            max-height: 13rem;
            position: absolute;
            video{
                position: initial;
            }
        }
    }
}
