.cards-row-container {
    h1 {
        text-align: center;
    }
}
.cards-row {
    margin: 5.11rem -1.11rem 0;
    display: flex;
    &__item {
        flex: 1 0 calc(33% - 2.22rem);
        max-width: calc(33% - 2.22rem);
        margin: 0 1.11rem;
        display: flex;
        flex-direction: column;
        min-height: 25rem;
        padding: 1.78rem 1.78rem 2.72rem 1.78rem;
        .item-top {
            flex: 2;
        }
        h2 {
            margin: 10px 0;
        }
        .link-block {
            justify-self: flex-end;
        }
    }
}
@media (max-width: 899px) {
    .cards-row {
        margin: 3rem -0.5rem 0;
        &__item {
            padding: 1.2rem 1.2rem 1.5rem 1.2rem;
            margin: 0 0.5rem;
            flex: 1 0 calc(33% - 1rem);
            max-width: calc(33% - 1rem);
        }
    }
}
@media (max-width: 790px) {
    .cards-row {
        margin: 2rem -0.5rem 0;
        &__item {
            padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        }
    }
}
@media (max-width: 760px) {
    .cards-row {
        flex-flow: row wrap;
        &__item {
            flex: 1 0 calc(50% - 1rem);
            max-width: calc(50% - 1rem);
            min-height: 10rem;
            margin-bottom: 2rem;
            .item-top {
                margin-bottom: 1.5rem;
            }
        }
    }
}
@media (max-width: 499px) {
    .cards-row {
        &__item {
            flex: 1 0 100%;
            max-width: 100%;
            min-height: 4rem;
            margin-bottom: 2rem;
            padding: 0.5rem 0;
            .item-top {
                margin-bottom: 1.5rem;
            }
        }
    }
}
