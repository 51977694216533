.form-group {
    margin-bottom: 1.11rem;
}
.invalid-feedback {
    color: $red;
    font-size: 12px;
    line-height: 1.4;
    text-align: right;
    margin-bottom: 9px;
}
.form-control {
    border-color: $black;
    border-radius: 0;
    padding: 16px 20px 17px 20px;
    color: $black;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1;
    height: 58px;
    &::placeholder {
        color: $black;
        opacity: 1;
        font-family: inherit;
    }
    &:-ms-input-placeholder {
        color: $black;
        opacity: 1;
        font-family: inherit;
    }
    &::-ms-input-placeholder {
        color: $black;
        opacity: 1;
        font-family: inherit;
    }
    &:focus {
        &::placeholder {
            color: transparent;
            opacity: 0;
        }
        &:-ms-input-placeholder {
            color: transparent;
            opacity: 0;
        }
        &::-ms-input-placeholder {
            color: transparent;
            opacity: 0;
        }
    }
    &:hover,
    &:focus {
        outline: none;
        border-color: $yellow;
        box-shadow: none;
    }
}

.form-check-label {
    span {
        display: block;
        text-transform: uppercase;
        &.small-text {
            margin-top: 8px;
            color: $gray10;
            font-size: 14px;
            text-transform: none;
        }
    }
    a {
        color: $black;
        border-bottom: 1px solid transparent;
        &:hover,
        &:focus {
            border-bottom-color: $orange;
        }
    }
}
.form-check {
    padding-left: 0;
    &.form-check-inline {
        input[type='radio'] + label {
            margin-bottom: 0;
        }
    }
    input[type='checkbox'] {
        position: absolute;
        opacity: 0;
        z-index: -1;
        margin: 0;
        & + label {
            position: relative;
            display: inline-block;
            padding: 0 2.44rem 0 0;
            height: 24px;
            line-height: 1.5;
            cursor: pointer;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                width: 24px;
                height: 24px;
                border: 1px solid $black;
                border-radius: 0;
                transition: 0.25s all ease;
            }
            &:after {
                content: '';
                position: absolute;
                top: 2px;
                right: 8px;
                display: block;
                height: 15px;
                width: 7px;
                transition: 0.25s all ease;
            }
        }
        & + label::after {
            content: '';
            display: inline-block;
            transform: rotate(45deg) scale(0);

            border-bottom: 2px solid $white;
            border-right: 2px solid $white;
        }
        &:checked + label::before {
            background: $black;
        }
        &:checked + label::after {
            transform: rotate(45deg) scale(1);
        }
        &:focus + label::before {
            outline: none;
        }
    }
    input[type='radio'] {
        opacity: 0;
        z-index: 1;
        margin: 0;
        position: absolute;
        & + label {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            padding-left: 33px;
            cursor: pointer;
            margin-bottom: 1.5rem;
            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                width: 22px;
                height: 22px;
                left: 0;
                border: 1px solid $black;
                border-radius: 50%;
                background-color: $white;
                transition: border 0.15s ease-in-out;
                cursor: pointer;
            }
            &::after {
                display: inline-block;
                position: absolute;
                content: ' ';
                width: 16px;
                height: 16px;
                left: 3px;
                top: 3px;
                border-radius: 50%;
                background-color: $black;
                transform: scale(0, 0);
                transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            }
        }
        &:focus + label::before {
            outline: none;
        }
        &:checked + label::after {
            transform: scale(1, 1);
        }
        &:disabled + label {
            opacity: 0.65;
        }
        &:disabled + label::before {
            cursor: not-allowed;
        }
    }
}
textarea {
    min-height: 10rem;
}
.form-login-block {
    text-align: center;
    .form-login-block-inside {
        background-color: #f5f5f5;
        padding:20px 15px;
        margin-bottom: 35px;
        h3 {
            max-width: 65%;
            margin:0 auto 20px auto;
        }
    }
    .additional-text {
        p {
            color:rgba($black, 0.7);
        }
    }
}
@media (max-width: 599px) {
    .form-check {
        input[type='checkbox'] {
            & + label {
                padding: 0 28px 0 0;
                height: 18px;
                &:before {
                    width: 18px;
                    height: 18px;
                }
                &:after {
                    top: 2px;
                    right: 7px;
                    height: 12px;
                    width: 5px;
                }
            }
        }
        input[type='radio'] {
            & + label {
                padding-left: 28px;
                margin-bottom: 1.5rem;
                &::before {
                    width: 18px;
                    height: 18px;
                }
                &::after {
                    width: 14px;
                    height: 14px;
                    left: 2px;
                    top: 2px;
                }
            }
        }
    }
}
