.gallery-block {
    &__inside {
        .gallery-row {
            display: flex;
            flex-flow: row wrap;
            margin: 0 -10px;

            .gallery-image {
                margin: 0 10px;
                position: relative;
                flex: 1 0 calc(100% - 20px);
                max-width: calc(100% - 20px);
                .gallery-image-block {
                    position: relative;
                    height:100%;
                    width: 100%;
                    .open-image {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        background-color: $black;
                        color: $white;
                        width: 27px;
                        height: 27px;
                        line-height: 27px;
                        text-align: center;
                        font-family: $notesEsa;
                        font-weight: normal;
                        font-size: 24px;
                        cursor: pointer;
                    }
                }
                img {
                    line-height: 0;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }

                .gallery-image-foot {
                    width: calc(100% - 27px);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background-color: $black;
                    color: $white;
                    padding-left: 5px;
                    padding-right: 25px;

                    .caption-text {
                        display: none;
                    }


                }

                &:hover {
                    .gallery-image-foot {
                        .caption-text {
                            display: block;
                        }
                    }
                }
            }

            &.one-third-two-thirds {
                .gallery-image {
                    flex: 1 0 calc(68% - 20px);
                    max-width: calc(68% - 20px);

                    &:first-child {
                        flex: 1 0 calc(32% - 20px);
                        max-width: calc(32% - 20px);
                    }
                }
            }

            &.two-thirds-one-third {
                .gallery-image {
                    flex: 1 0 calc(68% - 20px);
                    max-width: calc(68% - 20px);

                    &:last-child {
                        flex: 1 0 calc(32% - 20px);
                        max-width: calc(32% - 20px);
                    }
                }
            }

            &.two-half {
                .gallery-image {
                    flex: 1 0 calc(50% - 20px);
                    max-width: calc(50% - 20px);
                }
            }

            &.full-width {
                .gallery-image {
                    flex: 1 0 calc(100% - 20px);
                    max-width: calc(100% - 20px);
                }
            }
        }
    }
}
@media (max-width: 1060px) {
    .gallery-block{
        &__inside {
            .gallery-row {
                .gallery-image {
                    display: flex;
                    flex-flow:row wrap;
                    .gallery-image-block {
                        height: 10rem;
                        flex:1 0 100%;
                        width: 100%;
                    }
                    .gallery-image-foot {
                        position: initial;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        flex:1 0 auto;
                        .caption-text {
                            display: block;
                            padding: 0.5rem 0;
                            line-height: 1;
                            background-color: transparent;
                        }
                        .open-image {
                            top: calc(10rem - 24px);
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 760px) {
    .gallery-block {
        &__inside {
            .gallery-row {
                &.one-third-two-thirds {
                    .gallery-image {
                        flex: 1 0 calc(60% - 20px);
                        max-width: calc(60% - 20px);

                        &:first-child {
                            flex: 1 0 calc(40% - 20px);
                            max-width: calc(40% - 20px);
                        }
                    }
                }

                &.two-thirds-one-third {
                    .gallery-image {
                        flex: 1 0 calc(60% - 20px);
                        max-width: calc(60% - 20px);

                        &:last-child {
                            flex: 1 0 calc(40% - 20px);
                            max-width: calc(40% - 20px);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 499px) {
    .gallery-block {
        &__inside {
            .gallery-row {
                margin: 0;

                .gallery-image {
                    margin: 0;
                    a {
                        height: auto;
                    }

                    &:not(:last-child) {
                        margin-bottom: 2.22rem;
                    }

                    .gallery-image-foot {
                        .caption-text {
                            display: block;
                        }
                    }
                }

                &.one-third-two-thirds {
                    .gallery-image {
                        flex: 1 0 100%;
                        max-width: 100%;

                        &:first-child {
                            flex: 1 0 100%;
                            max-width: 100%;
                        }
                    }
                }

                &.two-thirds-one-third {
                    .gallery-image {
                        flex: 1 0 100%;
                        max-width: 100%;

                        &:last-child {
                            flex: 1 0 100%;
                            max-width: 100%;
                        }
                    }
                }

                &.two-half {
                    .gallery-image {
                        flex: 1 0 100%;
                        max-width: 100%;
                    }
                }

                &.full-width {
                    .gallery-image {
                        flex: 1 0 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
