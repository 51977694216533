.menu-block-text {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray02;
    padding-bottom: 2.22rem;
    .left-block {
        flex: 1 0 21%;
        max-width: 21%;
        align-self: center;
        h3 {
            font-weight: 700;
            line-height: 1.4;
            p {
                font-weight: 700;
                @include text-h3;
                line-height: 1.4;
                margin-bottom: 0;
            }
        }
    }
    .center-block {
        flex-grow: 2;
        margin: 0 3.33rem;
        p {
            margin-bottom: 0;
        }
    }
    .right-block {
        flex: 1 0 auto;
    }
}
.menu-block-columns {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .one-menu-column {
        flex: 1 0 calc(33% - 5.66rem);
        max-width: calc(33% - 5.66rem);
        padding: 10px 0 10px 20px;
        border-left: 2px solid $yellow;
        margin: 0 2.83rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
            margin-bottom: 10px;
            font-weight: 700;
            line-height: 1.4;
            p {
                font-weight: 700;
                @include text-h3;
                line-height: 1.4;
                margin-bottom: 0;
            }
        }
    }
}
@media (max-width: 899px) {
    .menu-block-columns {
        .one-menu-column {
            margin: 0 1rem;
            flex: 1 0 calc(33% - 2rem);
            max-width: calc(33% - 2rem);
        }
    }
}
@media (max-width: 750px) {
    .menu-block-columns {
        flex-flow: row wrap;
        .one-menu-column {
            margin: 0 0 1rem 0;
            flex: 1 0 100%;
            max-width: 100%;
        }
    }
}
@media (max-width: 499px) {
    .menu-block-text {
        flex-flow: row wrap;
        .left-block {
            flex: 1 0 100%;
            max-width: 100%;
        }
        .center-block {
            margin: 1rem 0;
        }
    }
}
