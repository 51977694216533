.breadcrumbs {
    margin-bottom: 1.11rem;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        li {
            margin-right: 10px;
            font-family: $notesEsa;
            color: $black;
            font-weight: normal;
            font-size: 0.77rem;
            line-height: 1.3;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            a {
                color: $black;
            }
            &:not(:first-child) {
                position: relative;
                &:before {
                    content: '/';
                    margin-right: 10px;
                }
            }
        }
    }
}

.page-intro {
    padding: 4.44rem 0 4.44rem 0;
    .page-title {
        display: flex;
        justify-content: space-between;
        &__left {
            max-width: 50%;
            flex: 1 0 50%;
            h1 {
                margin-bottom: 1.78rem;
            }
            .page-headline {
                text-transform: uppercase;
                margin-bottom: 1.78rem;
                font-family: 'NotesEsa';
                font-size: 1.4rem;
            }
            .page-description {
                h3 {
                    margin-bottom: 2.22rem;
                }
            }
        }
        &__right {
            max-width: 30%;
            flex: 1 0 30%;
            .small-tab-content {
                padding: 1.16rem 0 0 2.22rem;
            }

            .content-modules-wrapper {
                padding-top: 0;
                &.content-module-type-Contact {
                    padding-left: 24px;
                }
            }
            .contact-tabs-content {
                .content-modules-wrapper {
                    &.content-module-type-Contact {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    &.no-space {
        padding-bottom: 0;
    }
}
.block-simple-info {
    border-left: 1px solid $yellow;
    padding-left: 2.22rem;
    &__line {
        display: flex;
        align-items: center;
        padding-bottom: 1.06rem;
        .text-h3 {
            flex: 1 0 7.5rem;
            max-width: 7.5rem;
            font-size: 2.44rem;
            line-height: 1.2;
            text-align: right;
            margin-right: 10px;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
}
@media (max-width: 1000px) {
    .page-intro {
        .page-title {
            &__left {
                max-width: 58%;
                flex: 1 0 58%;
            }
            &__right {
                max-width: 38%;
                flex: 1 0 38%;
            }
        }
    }
}
@media (max-width: 790px) {
    .page-intro {
        .page-title {
            flex-flow: row wrap;
            &__left {
                max-width: 100%;
                flex: 1 0 100%;
                margin-bottom: 2rem;
            }
            &__right {
                max-width: 100%;
                flex: 1 0 100%;
                .container {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
    .block-simple-info {
        padding-left: 1.22rem;
    }
}
@media (max-width: 760px) {
    .block-simple-info {
        &__line {
            .text-h3 {
                font-size: 1.4rem;
                flex: 1 0 4.5rem;
                max-width: 4.5rem;
            }
        }
    }
    .breadcrumbs {
        ul {
            flex-flow: row wrap;
        }
    }
}
@media (max-width: 399px) {
    .page-intro .page-title__right .content-modules-wrapper.content-module-type-Contact {
        padding-left: 0;
    }
}
