.complex-text-list {
    h2 {
        strong,
        b {
            color: $yellow;
            font-weight: inherit;
        }
    }
}

.complex-list {
    margin-top: 3.22rem;
    max-width: 83%;

    &__item {
        margin-bottom: 1.39rem;

        &__heading {
            padding-bottom: 12px;
            margin-bottom: 1.11rem;
            border-bottom: 2px solid $yellow;
            display: flex;
            align-items: center;

            span {
                flex: 2;

                &.number {
                    flex: 1 0 20.4%;
                    max-width: 20.4%;
                }
            }
        }

        &__content {
            padding-left: 20.4%;

            p {
                margin: 0;
            }
        }

        &.bordered-content {
            .complex-list__item__content {
                padding: 1.33rem 0 1.11rem 0;
                border-bottom: 2px solid $yellow;
            }
        }
    }

    &.white-text {
        .complex-list__item {
            &__heading {
                span {
                    &.text-h2 {
                        color: $white;
                    }
                }
            }

            &__content {
                color: $white;

                p {
                    color: $white;
                }
            }
        }
    }
}

.collapsible {
    .complex-list {
        &__item {
            margin-bottom: 0;

            &__heading {
                display: flex;
                justify-content: space-between;
                padding-right: 0.5rem;
                border-bottom: 1px solid $black;

                .number {
                    display: none;
                }

                .text-h2 {
                    flex: 1 0 calc(100% - 2rem);
                    max-width: calc(100% - 2rem);
                    font-size: 1.33rem;
                    color: $black;

                    p {
                        font-size: 1.33rem;
                        color: $black;
                    }
                }

                .collapse-icon {
                    flex: 1 0 2rem;
                    max-width: 2rem;
                    text-align: right;
                    color: $black;
                    font-size: 1.33rem;
                    cursor: pointer;
                }
            }

            &__content {
                padding-left: 0;
                visibility: hidden;
                opacity: 0;
                height: 0;
                transition: height 0.3s linear;

                ul {
                    list-style: none;

                    li {
                        padding: 10px 0;
                        border-bottom: 1px solid;

                        &:before {
                            display: none;
                        }
                    }
                }
            }

            &.expanded {
                .complex-list__item__content {
                    opacity: 1;
                    visibility: visible;
                    height: auto;
                }

                .complex-list__item__heading {
                    border-bottom-color: $yellow;

                    .collapse-icon {
                        color: $yellow;
                    }
                }
            }
        }
    }
}

@media (max-width: 899px) {
    .complex-list {
        max-width: 100%;
    }
}

@media (max-width: 499px) {
    .complex-list {
        &__item {
            &__heading {
                span {
                    &.number {
                        flex: 1 0 50px;
                        max-width: 50px;
                    }
                }
            }

            &__content {
                padding-left: 50px;
            }
        }
    }
}

.content-module-type-TextRows {
    .complex-list {
        &__item {
            padding-bottom: 0;
            margin-top: .5rem;
            margin-bottom: .3rem;

            &__heading {
                padding-bottom: 0;
                margin-bottom: 0;
            }

            &__content {
                padding-bottom: 0;
                margin-bottom: 0;
                margin-top: .2rem;
            }
        }
    }

    &.color-palette-dark {
        .complex-list {
            &__item {
                &__heading {
                    border-color: #fff;

                    p,
                    .collapse-icon {
                        color: #fff;
                    }
                }
            }
        }
    }
}
