.video-block {
    padding-bottom: 2.22rem;

    &__inside {
        position: relative;
        display: flex;
        min-height: 18rem;

        img {
            width: 100%;
            height: 100%;
            line-height: 0;
            object-fit: cover;
        }

        .inner-wrap {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;

            .text-block {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;

                font-size: 2.5rem;
                line-height: 3rem;
                font-family: 'NotesEsa';

                .text-inner {
                    display: flex;
                    width: 80%;
                }
            }

            .button-block {
                display: flex;
                flex: 1;
                justify-content: flex-end;
                align-items: center;

                .link-block {
                    margin-right: 10%;

                    .button {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        a {
            display: block;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                line-height: 0;
                object-fit: cover;
            }
            &:hover,
            &:focus {
                .fa {
                    color: $yellow;
                }
            }
        }

        .fa {
            position: absolute;
            left: calc(50% - 11px);
            top: calc(50% - 8px);
            color: $white;
            font-size: 2rem;
            transition: all 0.3s linear;
        }
    }
}

@media (max-width: 890px) {
    .video-block {
        &__inside {
            min-height: 15rem;
            .inner-wrap {
                .text-block {
                    flex: 1 0 35%;
                }
            }
        }
    }
}
@media (max-width: 760px) {
    .video-block {
        &__inside {
            min-height: 15rem;
            img {
                height: auto;
            }
            a {
                img {
                    min-height: 15rem;
                    width: auto;
                }
            }
            .inner-wrap {
                flex-flow: row wrap;
                .text-block {
                    flex: 1 0 100%;
                    text-align: center;
                    font-size: 1.8rem;
                    line-height: 1.4;
                }
                .button-block {
                    justify-content: center;
                    .link-block {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
