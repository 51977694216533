.content-module-type-Table {
    .cmt-table-wrap {
        .cmt-row {
            width: 100%;
            display: flex;

            .cmt-column {
                display: flex;
                flex: 1;
                border-bottom: 1px solid rgba($black, 0.5);
                padding: 0.25rem;

                p {
                    margin: 0;
                }
            }
        }
    }
}
@media (max-width: 799px) {
    .content-module-type-Table {
        .cmt-table-wrap {
            .cmt-row {
                .cmt-column {
                    padding: 5px;
                    h3 {
                        font-size: 14px;
                    }
                    ul {
                        li {
                            font-size: 12px;
                        }
                    }
                    p {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .content-module-type-Table {
        padding: 0 1.5rem;
        .container {
            overflow-x: auto;
            padding: 0;
            .cmt-table-wrap {
                width: 150%;
            }
        }
    }
}
@media (max-width: 590px) {
    .content-module-type-Table {
        .container {
            .cmt-table-wrap {
                width: 250%;
            }
        }
    }
}
