.events-cat-nav {
    margin-bottom: 2.44rem;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $yellow;
        li {
            margin: 0 2.22rem;
            &:first-child {
                margin-left: 1.72rem;
            }
            a {
                @include bg-yellow;
                background-color: transparent;
                font-size: 1.11rem;
                &:hover,
                &:focus {
                    background-color: $yellow;
                }
            }
            &.active {
                a {
                    background-color: $yellow;
                }
            }
        }
    }
}
@media (max-width: 1100px) {
    .events-cat-nav {
        ul {
            li {
                margin: 0 1.5rem;
                &:first-child {
                    margin-left: 1rem;
                }
                a {
                    font-size: 1rem;
                }
            }
        }
    }
}
@media (max-width: 750px) {
    .events-cat-nav {
        margin-bottom: 1rem;
        ul {
            li {
                margin: 0 5px;
                &:first-child {
                    margin-left: 0;
                }
                a {
                    font-size: 0.8rem;
                }
            }
        }
    }
}
@media (max-width: 510px) {
    .events-cat-nav {
        margin-bottom: 1rem;
        ul {
            li {
                margin: 0 5px;
                &:first-child {
                    margin-left: 0;
                }
                a {
                    font-size: 0.8rem;
                    letter-spacing: 0.5px;
                }
            }
        }
    }
}
@media (max-width: 450px) {
    .events-cat-nav {
        margin-bottom: 1rem;
        ul {
            flex-flow: column;
            justify-content: flex-start;
            align-items: flex-start;
            li {
                margin: 0;
                &:first-child {
                    margin-left: 0;
                }
                a {
                    font-size: 0.8rem;
                    letter-spacing: 0.5px;
                }
            }
        }
    }
}
