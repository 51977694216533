.choose-file-wrap {
    position: relative;
    margin-top: .7rem;
    margin-left: .7rem;

    .choose-file-input {
        position: absolute;
        top: -.7rem;
        left: -.7rem;

        input {
            display: none;
        }

        label {
            cursor: pointer;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            border: 1px solid #000000;
            background-color: #fff;
            font-size: 0.8rem;
            line-height: 1.3rem;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            &:before {
                content: "\f040";
                font: normal normal normal 14px/1 FontAwesome;
            }
        }
    }

    .choose-file-preview {
        img {
            max-width: 100px;
        }

        video {
            max-width: 100px;
        }
    }

    &.image {

    }

    &.video {

    }
}
