.esa-icon {
    display: inline-block;
    vertical-align: middle;
    background-size: contain;
    &.arrow-left {
        background: url('../img/icons/arrow-left.svg') no-repeat 0 0;
        width: 25px;
        height: 14px;
        background-size: contain;
    }
    &.arrow-right {
        background: url('../img/icons/arrow-right.svg') no-repeat 0 0;
        width: 25px;
        height: 14px;
        background-size: contain;
    }
    &.arrow-right-white {
        background: url('../img/icons/arrow-right-white.svg') no-repeat 0 0;
        width: 24px;
        height: 13px;
        background-size: contain;
    }
    &.arrow-right-yellow {
        background: url('../img/icons/arrow-right-yellow.svg') no-repeat 0 0;
        width: 24px;
        height: 13px;
        background-size: contain;
    }
    &.arrow-up {
        background: url('../img/icons/arrow-up.svg') no-repeat 0 0;
        width: 13px;
        height: 24px;
        background-size: contain;
    }
    &.icon-times {
        background: url('../img/icons/icon-times.svg') no-repeat 0 0;
        width: 14px;
        height: 14px;
        background-size: contain;
    }
    &.icon-play {
        background: url('../img/icons/play.svg') no-repeat 0 0;
        width: 23px;
        height: 16px;
        background-size: contain;
    }
    &.icon-search {
        background: url('../img/icons/search.svg') no-repeat 0 0;
        width: 22px;
        height: 21px;
        background-size: contain;
    }
    &.icon-search-white {
        background: url('../img/icons/search-white.svg') no-repeat 0 0;
        width: 22px;
        height: 21px;
        background-size: contain;
    }
    &.icon-stop {
        background: url('../img/icons/stop.svg') no-repeat 0 0;
        width: 16px;
        height: 16px;
        background-size: contain;
    }
    &.arrow-angle {
        background: url('../img/icons/arrow-angle.svg') no-repeat 0 0;
        width: 10px;
        height: 10px;
        background-size: contain;
    }
    &.menu-arrow {
        background: url('../img/icons/menu-arrow.svg') no-repeat 0 0;
        width: 8.3px;
        height: 9.92px;
        background-size: contain;
        margin-left: 5px;
    }
    &.close-icon {
        background: url('../img/icons/close.svg') no-repeat 0 0;
        width: 12px;
        height: 12px;
        background-size: contain;
    }
}
