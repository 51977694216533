.content-module-type-TextRowsWithImages {
    .complex-text-list {
        h2.module-title,
        h2.module-title div p {
            text-transform: none;
            font-size: 1rem;
            font-weight: bold;
            font-family: Arial;
        }

        .complex-list {
            margin-top: 2rem;

            .complex-list__item {
                .complex-list__item__heading {
                    margin: 0;
                    padding: .7rem .5rem;
                    cursor: pointer;

                    .collapse-icon {
                        font-size: 1rem;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }

                .complex-list__item__content {
                    display: none;
                    margin: 0;
                    padding: 0 0 .7rem;
                    border-bottom: none;

                    .item-text-wrap,
                    .item-images-wrap {
                        padding: .7rem 0 0;
                    }

                    .item-images-wrap {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-auto-rows: minmax(0, auto);
                        gap: 0.7rem;

                        .item-image-wrap {
                            padding: 0;

                            img {
                                width: 100%;
                            }
                        }

                        &.images-columns-2 {
                            grid-template-columns: repeat(2, 1fr);
                        }
                    }

                    ul {
                        list-style: initial;
                        margin-left: 1.11rem;

                        li {
                            border-bottom: none;
                            margin-bottom: initial;
                            padding: 3px 0;
                        }
                    }
                }

                &.expanded {
                    .complex-list__item__heading {
                        .collapse-icon {
                            color: #000;
                            font-size: .7rem;
                        }
                    }

                    .complex-list__item__content {
                        display: block;
                    }
                }
            }
        }
    }
}
