.advanced-list {
    &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4.44rem;
        &__left {
            flex: 2;
            h2 {
                margin-bottom: 1.11rem;
            }
            &__excerpt {
                margin-bottom: 1.11rem;
            }
            &__date {
                display: flex;
                align-items: center;
                span:not(:last-child) {
                    margin-right: 1.11rem;
                }
            }
        }
        &__right {
            flex: 1 0 58%;
            max-width: 58%;
            margin-left: 4.39rem;
            padding-bottom: 1.11rem;
            border-bottom: 1px solid $gray02;
            .tags-list {
                border-bottom: 1px solid $gray02;
                padding-bottom: 1.11rem;
                margin-bottom: 1.11rem;
                font-weight: normal;
                font-size: 0.78rem;
                line-height: 1.2;
                font-family: $notesEsa;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                span {
                    margin-right: 1.11rem;
                }
            }
            .content-block {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1.11rem;
                &__text {
                    flex: 1 0 56%;
                    max-width: 56%;
                    margin-right: 1.22rem;
                }
            }
        }
    }
}
@media (max-width: 899px) {
    .advanced-list {
        &__item {
            &__right {
                margin-left: 2.5rem;
            }
        }
    }
}
@media (max-width: 790px) {
    .advanced-list {
        &__item {
            flex-flow: row wrap;
            &__right {
                flex: 1 0 100%;
                max-width: 100%;
                margin: 0.5rem 0 0 0;
            }
        }
    }
}
@media (max-width: 499px) {
    .advanced-list {
        &__item {
            &__right {
                .content-block {
                    flex-flow: row wrap;
                    &__text {
                        flex: 1 0 100%;
                        max-width: 100%;
                        margin: 0 0 1rem 0;
                    }
                }
            }
        }
    }
}
