.newsletter-wrap {
    padding: 4rem 0;

    .collapsed-ctrl-wrap {
        display: none;

        button {
            margin-top: 1rem;
        }
    }

    .form-wrap {
        display: block;
    }

    &.collapsed {
        .collapsed-ctrl-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .form-wrap {
            display: none;
        }
    }

    .footer-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        a {
            text-decoration: underline;
            color: $gray09;
        }
    }
}
