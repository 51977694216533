.content-module-type-Links {
    &.style-underline {
        .one-list-block {
            h4 {
                margin-bottom: 0.5rem;
            }

            ul {
                li {
                    padding-left: 0;

                    &:before {
                        display: none;
                    }

                    a {
                        text-decoration: underline;
                        font-size: 0.78rem;
                    }
                }
            }
        }
    }

    &.big-main-title {
        .services-block__title {
            margin-bottom: 4rem;
            span {
                @extend .text-h2;
                background-color: transparent;
            }
        }
    }
    &.font-size-BIG {
        .services-list {
            .one-list-block {
                h4 {
                    font-size: 1.33rem;
                }
                ul {
                    li {
                        font-size: 0.78rem;
                        padding-left: 2rem;
                        &:before {
                            content: '';
                            background: url('../img/icons/arrow-right.svg') no-repeat 0 0;
                            width: 25px;
                            height: 14px;
                            transition: all 0.3s linear;
                        }
                        &:hover,
                        &:focus {
                            &:before {
                                background: url('../img/icons/arrow-right-yellow.svg') no-repeat 0 0;
                            }
                        }
                        a {
                            font-size: 0.78rem;
                        }
                    }
                }
            }
        }
    }
}
