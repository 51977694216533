.tabs-links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $yellow;

    li {
        margin: 0 1.22rem;

        &:first-child {
            margin-left: 1.72rem;
        }

        a {
            @include bg-yellow;
            background-color: transparent;
            font-size: 1rem;
            letter-spacing: 0.5px;

            &:hover,
            &:focus {
                background-color: $yellow;
                color: $white !important;
            }
        }

        &.active {
            a {
                background-color: $yellow;
                color: $white;
            }
        }

        &:before {
            display: none;
        }
    }
}

.filter-group {
    margin-bottom: 1rem;

    .filter-lvl-1 {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-bottom: 1px solid $yellow;

        li {
            margin: 10px 0;

            a {
                @include bg-yellow;
                background-color: transparent;
                font-size: 1.11rem;

                &:hover,
                &:focus {
                    background-color: $black;
                    color: $white;
                }
            }

            &.active {
                a {
                    background-color: $black;
                    color: $white;
                }
            }
        }
    }

    .filter-lvl-2 {
        .filter-type {
            box-shadow: 0px 24px 54px rgba($black, 0.2);
            display: flex;
            flex-flow: column wrap;
            transition: height 0.3s linear;
            height: 0;
            opacity: 0;
            visibility: hidden;

            &.opened {
                height: auto;
                opacity: 1;
                visibility: visible;
                padding: 1.33rem 1.77rem 2.39rem;

                .open-filters {
                    visibility: hidden;
                    display: none;
                }
            }
        }
    }

    .filter-lvl-2 {
        .form-group {
            > div {
                display: flex;
                flex-flow: column wrap;
                max-height: 10rem;

                .form-check {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}

.labs-filter-information {
    margin: 0 19.5% 2.22rem;
    opacity: 0;
    height: 0;
    visibility: hidden;

    h2 {
        margin-bottom: 1.11rem;
    }

    &.show {
        opacity: 1;
        visibility: visible;
        height: auto;
    }
}

@media (max-width: 1100px) {
    .tabs-links {
        li {
            margin: 0 0.8rem;

            &:first-child {
                margin-left: 0.5rem;
            }

            a {
                font-size: 0.9rem;
            }
        }
    }
    .filter-group {
        .filter-lvl-1 {
            li {
                a {
                    font-size: 0.89rem;
                    letter-spacing: 0;
                }
            }
        }

        .filter-lvl-2 {
            .form-group {
                > div {
                    max-height: 20rem;

                    .form-check {
                        .form-check-label {
                            font-size: 0.9rem;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 890px) {
    .tabs-links {
        li {
            margin: 0 0.5rem;

            a {
                font-size: 0.8rem;
            }
        }
    }
}

@media (max-width: 790px) {
    .tabs-links {
        li {
            margin: 0 0.5rem;

            a {
                font-size: 0.8rem;
                letter-spacing: 0;
            }
        }
    }
}

@media (max-width: 760px) {
    .tabs-links {
        li {
            margin: 0 3px;

            &:first-child {
                margin-left: 3px;
            }

            a {
                font-size: 10px;
                letter-spacing: 0;
            }
        }
    }
    .filter-group {
        .filter-lvl-1 {
            flex-flow: row wrap;
            justify-content: flex-start;

            li {
                margin: 4px 0;
            }
        }

        .filter-lvl-2 {
            .filter-type {
                &.opened {
                    .open-filters {
                        visibility: visible;
                        display: block;
                        text-align: center;
                        color: $black;

                        &:hover {
                            color: $black;

                            .open-filters-label {
                                border-bottom: 1px solid $yellow;
                            }
                        }

                        .open-filters-label {
                            border-bottom: 1px solid transparent;
                        }

                        &.hide {
                            .hide-text {
                                display: inline-block;
                            }

                            .show-text {
                                display: none;
                            }
                        }

                        .hide-text {
                            display: none;
                        }
                    }

                    .filter-container {
                        height: 0;
                        visibility: hidden;
                        opacity: 0;
                        margin: 0;
                        transition: height 0.3s linear, opacity 0.6s linear;

                        &.show {
                            visibility: visible;
                            height: auto;
                            opacity: 1;
                            margin: 1rem 0 0 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 560px) {
    .tabs-links {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        li {
            margin: 0 0.5rem;

            &:first-child {
                margin-left: 0.5rem;
            }

            a {
                font-size: 0.9rem;
            }
        }
    }
}

@media (max-width: 520px) {
    .tabs-links {
        li {
            margin: 0 0.5rem;

            &:first-child {
                margin-left: 0.5rem;
            }

            a {
                font-size: 0.9rem;
            }
        }
    }
    .filter-group {
        .filter-lvl-2 {
            .filter-type {
                &.opened {
                    padding: 1rem 1.3rem 0.5rem 1.3rem;
                }
            }

            .form-group {
                & > div {
                    flex-flow: column;
                    max-height: initial;
                }
            }
        }
    }
}

@media (max-width: 399px) {
    .tabs-links {
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;

        li {
            margin: 2px 0;

            &:first-child {
                margin-left: 0;
            }

            a {
                font-size: 0.9rem;
            }
        }
    }
    .filter-group {
        .filter-lvl-1 {
            li {
                margin: 4px 0;
            }
        }
    }
}
