@mixin text-h2 {
    font-weight: normal;
    font-size: 2rem;
    line-height: 1.19;
    margin: 0;
    padding: 0;
    color: $black;
    font-family: $notesEsa;
    text-transform: uppercase;
}
@mixin text-h2-bold {
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    color: $black;
    font-family: $notesEsa;
    text-transform: uppercase;
}
@mixin text-h2-home {
    font-weight: normal;
    font-size: 3.33rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    color: $white;
    font-family: $notesEsa;
    text-transform: uppercase;
}
@mixin text-h3 {
    font-weight: normal;
    font-size: 1.33rem;
    line-height: 1.16;
    margin: 0;
    padding: 0;
    color: $black;
    font-family: $notesEsa;
    text-transform: uppercase;
}
@mixin text-h3-alt {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    color: $black;
    font-family: $notesEsa;
    text-transform: uppercase;
}
@mixin text-h4 {
    font-weight: normal;
    font-size: 0.78rem;
    line-height: 1;
    margin: 0;
    padding: 0;
    color: $black;
    text-transform: uppercase;
}
@mixin bg-yellow {
    background-color: $yellow;
    color: $black;
    padding: 4px 8px;
    letter-spacing: 1.66px;
    font-size: 0.86rem;
    line-height: 1.3;
    font-family: $notesEsa;
    font-weight: normal;
    text-transform: uppercase;
}
