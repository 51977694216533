.events-archive-filters {
    display: flex;
    align-items: center;
    border-bottom: 2px solid $yellow;
    padding: 0 1.72rem;
    margin-bottom: 3.33rem;
    .events-filter {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        margin-bottom: -1px;
        .events-filter-label {
            color: $black;
            font-size: 1rem;
            letter-spacing: 0.5px;
            line-height: 1.3;
            font-family: $notesEsa;
            font-weight: normal;
            text-transform: uppercase;
        }
        .form-group {
            margin: 0 1.5rem;
            position: relative;
            .form-control {
                border:1px solid $yellow;
                padding: 10px 30px 10px 10px;
                color: $black;
                font-size: 1rem;
                letter-spacing: 0.5px;
                line-height: 1.3;
                font-family: $notesEsa;
                height: auto;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
            &:after {
                content: " ";
                position: absolute;
                right: 15px;
                top:17px;
                color:$black;
                pointer-events: none;
                border: solid black;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }
        }
    }
}
.items-list.events-archive-list {
    .items-list-heading {
        padding: 1rem 0;

        justify-content: flex-start;
        span {
            &:first-child {
                flex:1 0 12%;
                max-width:12%;
                margin-right: 4%;
            }
            &:nth-child(2) {
                flex: 1 0 calc(42% - 2.5rem);
                max-width: calc(42% - 2.5rem);
                margin-right: 4%;
            }
            &:nth-child(3) {
                flex:1 0 14%;
                max-width:14%;
                margin-right: 4%;
            }
            &:last-child {
                flex:1 0 14%;
                max-width:14%;
                margin-right: 4%;
            }
        }
    }
    .event-arvhive-item-single {
        padding:12px 0;
        .items-list-single-main {
            justify-content: flex-start;
            padding: 0 1.72rem 0 0;
            .text-h3 {
                font-size:0.89rem;
            }
            span {
                &:first-child {
                    flex:1 0 12%;
                    max-width:12%;
                    margin-right: 4%;
                }
                &:nth-child(2) {
                    flex: 1 0 calc(44% - 2.5rem);
                    max-width: calc(44% - 2.5rem);
                    margin-right: 4%;
                }
                &:nth-child(3) {
                    flex:1 0 14%;
                    max-width:14%;
                    margin-right: 4%;
                }
                &:nth-child(4) {
                    flex:1 0 14%;
                    max-width:14%;
                    margin-right: 4%;
                }
            }
        }
        .items-list-single-extra {
            .item-image {
                padding: 12px 0 0 0;
            }
        }
    }
}
@media all and (max-width:1100px) {
    .events-archive-filters {
        padding: 0;
        .events-filter {
            margin-right: 0.5rem;
            .form-group {
                margin:0 1rem;
            }
        }
    }
}
@media all and (max-width:899px) {
    .events-archive-filters {
        padding: 0;
        .events-filter {
            .events-filter-label {
                font-size:0.89rem;
            }
            .form-group {
                font-size:0.89rem;
                margin:0.5rem;
            }
        }
    }
}
@media all and (max-width:767px) {
    .events-archive-filters {
        flex-flow: row wrap;
        .events-filter {
            flex:1 0 100%;
            max-width: 100%;
            margin-bottom: 0.5rem;
        }
    }
    .items-list.events-archive-list .event-arvhive-item-single .items-list-single-main {
        padding: 0;;
    }
    .items-list.events-archive-list .items-list-heading span:nth-child(2) {
        flex: 1 0 calc(44% - 2.5rem);
        max-width: calc(44% - 2.5rem);
        margin-right: 4%;
    }
}
@media all and (max-width:599px) {
    .items-list.events-archive-list {
        .items-list-heading {
            display: none;
        }
        .event-arvhive-item-single {
            .items-list-single-main {
                flex-flow:row wrap;
                position: relative;
                span{
                    &.text-mobile-visible {
                        display: block;
                        margin-bottom: 0 !important;
                    }
                    &:first-child {
                        flex:1 0 100%;
                        max-width: 100%;
                        margin-right: 0;
                        margin-bottom: 1rem;
                    }
                    &:nth-child(2) {
                        flex:1 0 100%;
                        max-width: 100%;
                        margin-right: 0;
                        margin-bottom: 1rem;
                    }
                    &:nth-child(3) {
                        flex:1 0 100%;
                        max-width: 100%;
                        margin-right: 0;
                        margin-bottom: 1rem;
                    }
                    &:nth-child(4) {
                        flex:1 0 100%;
                        max-width: 100%;
                        margin-right: 0;
                    }
                    &.toggle-event {
                        margin: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        flex: auto;
                        max-width: initial;
                    }
                }

            }
            .items-list-single-extra {
                .item-description {
                    margin-right: 0;
                    flex:1 0 100%;
                    max-width: 100%;
                    padding: 1rem 0;
                }
                .item-info-2 {
                    padding: 0;
                    .item-info-row {
                        .item-info-right,
                        .item-info-left {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
}
