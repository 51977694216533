.services-block {
    padding: 8.9rem 0;
    &__title {
        margin-bottom: 1.5rem;
        .with-html {
            display: inline-block;
            p {
                margin-bottom: 0;
                letter-spacing: 1.66px;
                font-size: 0.86rem;
                line-height: 1.3;
                font-family: $notesEsa;
                font-weight: normal;
                text-transform: uppercase;
            }
        }
    }
    &__inside {
        .subtitle {
            margin-bottom: 1.5rem;
        }
    }
}
.services-list {
    display: flex;
    flex-flow: row wrap;
    .one-list-block {
        margin: 0 1.11rem 4.44rem 0;
        flex: 1 0 calc(25% - 1.11rem);
        max-width: calc(25% - 1.11rem);
        h4 {
            margin-bottom: 2.22rem;
        }
        &.has-image {
            margin: 0;
            h4 {
                @include bg-yellow;
            }
        }
    }
}
@media (max-width: 899px) {
    .services-list {
        .one-list-block {
            margin: 0 1.11rem 2.22rem 0;
            flex: 1 0 calc(33% - 1.11rem);
            max-width: calc(33% - 1.11rem);
        }
    }
}
@media (max-width: 760px) {
    .services-list {
        .one-list-block {
            margin: 0 1.11rem 2.22rem 0;
            flex: 1 0 calc(50% - 1.11rem);
            max-width: calc(50% - 1.11rem);
        }
    }
}
@media (max-width: 499px) {
    .services-list {
        .one-list-block {
            margin: 0 0 1.5rem 0;
            flex: 1 0 100%;
            max-width: 100%;
        }
    }
}
