.homer-list {
    margin-bottom: 5rem !important;

    .container {
        display: flex;
        flex-direction: column;
    }

    &.smaller-width {
        .items-list {
            width: 80%;
            align-self: flex-end;
        }
    }

    .list-title-wrap {
        margin-bottom: 3rem;

        .list-title {
            @extend .bg-yellow;
            display: inline-block;
        }
    }

    &.button-align-bottom {
        .items-list-single.opened {
            .items-list-single-extra {
                &__left {
                    display: flex;
                    flex-direction: column;

                    .after-description {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;

                        .content-module-type-SimpleList {
                            padding: .5rem 0;
                            padding-bottom: 1rem;
                            border-bottom: 2px dashed #f1f1f1;
                        }
                    }

                    .button-block {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 799px) {
    .homer-list {
        &.smaller-width {
            .items-list {
                width: 100%;
            }
        }
    }
}
