.content-modules-wrapper {
    &.content-module-type-Slider {
        .container {
            max-width: 100%;
            overflow: hidden;
            padding-left: calc((100vw - 1216px) / 2);
        }
        .slider-title {
            margin-bottom: 0;
            @include bg-yellow;
            display: inline-block;
        }
        .slider-arrows {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 2.94rem;
            a {
                background: url('../img/icons/arrow-right.svg') no-repeat 0 0;
                width: 25px;
                height: 14px;
                background-size: contain;
                text-indent: -9999px;
                display: block;
                margin: 0 1.83rem;
                transition: all 0.3s linear;
                &:first-child {
                    transform: rotate(-180deg);
                }
                &:hover,
                &:focus {
                    background: url('../img/icons/arrow-right-yellow.svg') no-repeat 0 0 !important;
                }
            }
        }
        .slick-track {
            display: flex !important;
        }

        .slick-slide {
            height: inherit !important;
            display: flex;
            &:nth-child(2n) {
                .slide {
                    background-color: $black;
                    h3,
                    h4,
                    p,
                    ul li,
                    a {
                        color: $white;
                    }
                }
            }
            & > div {
                width: 100%;
            }
        }
        .slide {
            padding: 3.33rem;
            border-left: 2px solid $yellow;
            height: 100%;
            .content-modules-wrapper {
                padding: 0;
                .container {
                    overflow: initial;
                    padding-left: 0;
                }
            }

            h3 {
                text-transform: uppercase;
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }

            h4 {
                text-transform: uppercase;
                font-size: 0.8rem;
                margin-bottom: 1rem;
                font-family: $notesEsa;
                letter-spacing: 1.5px;
            }

            p {
            }

            ul {
                list-style: none;
                margin: 0 0 2.33rem 0;
                padding: 0;

                li {
                    display: flex;
                    align-items: center;
                    border-style: solid;
                    border-color: $gray02;
                    border-width: 1px 0 0;
                    padding: 0.5rem 0;
                    margin-bottom: 0;
                    &:before {
                        display: none;
                    }

                    &:last-child {
                        border-width: 1px 0;
                    }

                    strong {
                        font-weight: normal;
                        flex: 2;
                    }

                    em {
                        font-style: normal;
                        font-size: 0.8rem;
                        font-family: $notesEsa;
                        letter-spacing: 1.5px;
                        max-width: 38%;
                        flex: 1 0 38%;
                    }
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .content-modules-wrapper {
        &.content-module-type-Slider {
            .container {
                padding-left: 1rem;
                padding-right: 0;
            }
        }
    }
}
@media (max-width: 499px) {
    .content-modules-wrapper {
        &.content-module-type-Slider {
            .slide {
                padding: 1rem;
            }
        }
    }
}
