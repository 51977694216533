.contact-tabs-links {
    border-bottom: 1px solid $yellow;
    display: flex;
    a {
        margin: 0 2.22rem;
        display: block;
        padding: 4px 12px;
        color: $black;
        text-transform: uppercase;
        font-family: $notesEsa;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 1.5px;
        &:hover,
        &:focus,
        &.active {
            outline: none;
            box-shadow: none;
            background-color: $yellow;
        }
    }
}
.contact-tabs-content {
    padding: 21px 0 0 24px;
}

.person-block {
    margin-top: 1.11rem;
    display: flex;
    align-items: center;

    .person-avatar {
        flex: 1 0 3.33rem;
        height: 3.33rem;
        max-width: 3.33rem;
        margin-right: 10px;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    .text-block {
        h4 {
            font-family: $notesEsa;
            font-weight: bold;
        }

        .small-text {
            font-size: 12px;
            line-height: 1;
            margin: 4px 0;
            letter-spacing: 1px;
            display: block;
        }

        .person-contact {
            display: flex;
            align-items: center;
            line-height: 1;

            a {
                font-size: 12px;
                line-height: 1;
                color: $black;
                margin-right: 10px;
                transition: all 0.3s linear;
                border-bottom: 1px solid transparent;
                &:hover,
                &:focus {
                    outline: none;
                    border-bottom-color: $yellow;
                }
            }

            .esa-icon.arrow-angle {
                transition: all 0.3s linear;
            }
            &:hover,
            &:focus {
                a {
                    border-bottom-color: $yellow;
                }
                .esa-icon.arrow-angle {
                    background: url('../img/icons/arrow-angle-yellow.png') no-repeat 0 0;
                }
            }
        }
    }
}
.resources-list {
    .one-resource {
        a {
            display: flex;
            color: $black;
            align-items: center;
            span {
                border-bottom: 1px solid transparent;
                transition: all 0.3s linear;
            }
            i {
                flex: 1 0 20px;
                max-width: 20px;
                margin-left: 20px;
                transition: all 0.3s linear;
            }
            &:hover,
            &:focus {
                span {
                    border-bottom-color: $yellow;
                }
                i {
                    color: $yellow;
                }
            }
        }
    }
}

@media (max-width: 399px) {
    .contact-tabs-content {
        padding: 15px 0 0 0;
    }
}
