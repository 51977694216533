.button {
    padding: 8px 16px;
    font-size: 0.78rem;
    line-height: 1.3;
    font-family: $notesEsa;
    letter-spacing: 1.5px;
    font-weight: normal;
    border: 1px solid $black;
    color: $black;
    display: inline-block;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    span {
        line-height: 1.33rem;
    }
    i {
        margin-left: 10px;
        transition: all 0.3s linear;
    }

    &.white {
        border-color: $white;
        color: $white;
        &:hover {
            outline: none;
            color: $black;
            background-color: $white;
            .esa-icon {
                background-image: url('../img/icons/arrow-right.svg');
            }
        }
        &:focus {
            outline: none;
            color: $black;
            background-color: $gray03;
            .esa-icon {
                background-image: url('../img/icons/arrow-right.svg');
            }
        }
    }
    &.button-download {
        .fa {
            margin: 0 10px 0 0;
        }
    }
    &.black {
        background-color: $black;
        color: $white;
        .esa-icon {
            background-image: url('../img/icons/arrow-right-white.svg');
        }
        &:hover {
            outline: none;
            color: $black;
            background-color: $yellow;
            border-color: $yellow;
            .esa-icon {
                background-image: url('../img/icons/arrow-right.svg');
            }
        }
        &:focus {
            outline: none;
            color: $black;
            background-color: $yellow01;
            border-color: $yellow01;
            .esa-icon {
                background-image: url('../img/icons/arrow-right.svg');
            }
        }
    }
    &:hover {
        outline: none;
        color: $white;
        background-color: $black;
        .esa-icon {
            background-image: url('../img/icons/arrow-right-white.svg');
            text-decoration: none;
        }
    }
    &:focus {
        outline: none;
        color: $white;
        background-color: $gray09;
        .esa-icon {
            background-image: url('../img/icons/arrow-right-white.svg');
        }
    }
    &.full-width {
        width: 100%;
        justify-content: center;
    }
}
