.all-news-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4.83rem;
}
.news-pagination {
    list-style: none;
    padding: 0;
    margin:0;
    display: flex;
    align-items: center;
    li {
        margin:0 10px;
        &.one-item {
            &.active {
                .page-link {
                    color:$black;
                    text-decoration: underline;
                }
            }
        }
        .page-link {
            border:none;
            padding: 0;
            background-color: transparent;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 1.5px;
            font-family: $notesEsa;
            color: #777;
            &:hover {
                outline: none;
                border: none;
                text-decoration: underline;
                color:$black;
            }
        }
        a {
            &:hover,
            &:focus {
                .esa-icon.arrow-left {
                    background: url("../img/icons/arrow-right-yellow.svg") no-repeat 0 0;
                    transform: rotate(-180deg);
                }
                .esa-icon.arrow-right {
                    background: url("../img/icons/arrow-right-yellow.svg") no-repeat 0 0;
                }
            }
        }
    }
}
@media (max-width: 599px) {
    .all-news-navigation {
        flex-flow:row wrap;
        justify-content: center;
        .all-news-navigation-left {
            flex:1 0 100%;
            max-width: 100%;
            margin-bottom: 20px;
            text-align: center;
        }
    }
}
